import React from "react";
import { observer } from "mobx-react-lite";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import { Hooks } from "shared";
import { CategorySelectModal } from "./category-select-control";
import { InputControl } from "./input-control";
import { SearchControlModal } from "./search-control-modal";
import { SearchControlContext } from "./search-control.context";
import * as Markup from "./saerch-control.styles";
import { useSearchControl } from "./search-control.hooks";
import { Store } from "app";
import { useLocation, useNavigate } from "react-router-dom";

const searchButtonStyle = {
    "boxShadow": "unset",

    "&:hover, &:focus": {
        boxShadow: "unset",
    },
};

export const SearchControl = observer(() => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const modalRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const {
        searchString,
        modalContentType,
        onTextChange,
        handleCategoryClick,
        handleInputFocus,
        handleSubmit,
        handleClose,
    } = useSearchControl();
    const { searchController, submit } = Store.Hooks.useAdvertisementList();
    const navigate = useNavigate();
    const location = useLocation();

    const isOpened = modalContentType !== null;

    const handleEnter = () => {
        submit();
        searchController.setModalContentType(null);

        if (!location.pathname.includes("category")) {
            navigate("/category/all");
        }
    };

    Hooks.useClickOutside({ ref: containerRef, callback: handleClose });

    return (
        <SearchControlContext.Provider value={{ containerRef, modalRef, inputRef }}>
            <Markup.Container $isOpened={isOpened} ref={containerRef}>
                <Markup.Outline $isOpened={isOpened}>
                    <Markup.InputSection $isOpened={isOpened}>
                        <CategorySelectModal onClick={handleCategoryClick} />
                        <InputControl
                            ref={inputRef}
                            value={searchString}
                            onFocus={handleInputFocus}
                            onChange={onTextChange}
                            onEnter={handleEnter}
                        />
                    </Markup.InputSection>
                    <Button sx={searchButtonStyle} variant="contained" onClick={handleSubmit}>
                        <SearchIcon sx={{ fontSize: "30px !important" }} />
                    </Button>
                </Markup.Outline>
                {modalContentType !== null && containerRef.current && (
                    <SearchControlModal
                        ref={modalRef}
                        width={containerRef.current.offsetWidth}
                        contentType={modalContentType}
                        onClose={handleClose}
                    />
                )}
            </Markup.Container>
        </SearchControlContext.Provider>
    );
});
