export const colors = {
    black: {
        basic: "#000000",
    },
    white: {
        basic: "#FFFFFF",
    },
    containerBackground: {
        primary: {
            default: "",
            hover: "#1E3677",
            active: "#5e35b1",
            disabled: "",
        },
        secondary: {
            default: "",
            hover: "",
            active: "",
            disabled: "",
        },
        tertiary: {
            default: "",
            hover: "",
            active: "",
            disabled: "",
        },
        success: {
            default: "",
            hover: "",
            active: "",
            disabled: "",
        },
        info: {
            default: "",
            hover: "",
            active: "",
            disabled: "",
        },
        warning: {
            default: "",
            hover: "",
            active: "",
            disabled: "",
        },
        danger: {
            default: "#f04848",
            hover: "",
            active: "",
            disabled: "",
        },
    },
    content: {
        primary: "#1E2666",
        secondary: "#9e9e9e",
        tertiary: "",
        quaternary: "",
    },
    semantic: {},
    overlayBackground: {},
    outline: {},
    primaryHover: "#5B28F6",
    primaryActive: "#4C14F5",
    danger: "#f04848",
    background: {
        primary: "rgba(27, 108, 108, 0.03)",
        secondary: "#6a3df7",
        tertiary: "linear-gradient(60deg, rgba(2,0,36, 0.4) 0%, rgb(135, 96, 250) 35%, #6a3df7 50%)",
        quaternary: "#3B3B3B",
    },
    typography: {
        text: {
            primary: "#000000",
            secondary: "#555555",
            tiny: "#fefefe",
            link: " #4a148c",
        },
    },
    status: {
        online: "#00cd00",
        offline: "#ee9f27",
    },
};
