import { reaction } from "mobx";
import { Store } from "app/index";

export class WishListAuth {
    constructor(private appState: Store.AppState) {
        this.subscribe();
    }

    private subscribe() {
        reaction(() => this.appState.auth.isLogin, this.onLoginChange);

        if (this.appState.auth.isLogin) {
            this.appState.wishList.loadList();
        }
    }

    private onLoginChange = (isLogin: boolean) => {
        if (isLogin) {
            this.appState.wishList.loadList();
        } else {
            this.appState.wishList.reset();
        }
    };
}
