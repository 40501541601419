import styled from "styled-components";

export const GridCard = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 270px;
    height: 308px;
    overflow: hidden;
    padding: 8px;
    border-radius: 12px;
    transition: 0.2s;

    &:hover {
        box-shadow: 0 4px 12px #24262920;
    }
`;

export const InfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding-top: 8px;
    flex-grow: 1;
`;

export const Price = styled.span`
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
`;

export const SubText = styled.span`
    color: rgb(88, 88, 88);
    font-size: 16px;
    line-height: 24px;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
`;
