import React from "react";
import { observer } from "mobx-react-lite";
import { languageStorageManager } from "shared/i18n";
import { Locale, formatRelative } from "date-fns";
import { ru, enUS, hy } from "date-fns/locale";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import { AdvertisementDomain } from "app-domain";
import { Store } from "app";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { ImagePreview, Head } from "./components";
import * as Markup from "./grid-card.styles";

const language: Record<"ru" | "en" | "hy", Locale> = {
    ru,
    en: enUS,
    hy,
};

const locale: Locale = language[languageStorageManager.language as "ru" | "en" | "hy"];

interface Props {
    isLoading: boolean;
    isWishlistButtonVisible?: boolean;
    id?: string;
    title?: string;
    images?: string[];
    createdAt?: string;
    location?: AdvertisementDomain.Location;
    marker?: string;
    description?: string;
    price?: AdvertisementDomain.Price;
    style?: React.CSSProperties;
}

export const AdvertisementGridCard = observer((props: Props) => {
    const wishListStore = Store.Hooks.useWishList();
    const dictionary = Store.Hooks.useDictionary();
    const price = props.price?.value.start;
    const currency = props.price?.currencyType ? dictionary.currencies.get(props.price?.currencyType)?.symbol : "";
    const date =
        props.createdAt &&
        formatRelative(new Date(props.createdAt), new Date(), {
            locale,
        });

    const isInWishList = !!props.id && wishListStore.availableIds.has(props.id);

    const handleWishListClick = () => {
        if (!props.id) return;

        if (isInWishList) {
            wishListStore.removeFormList(props.id);
        } else {
            wishListStore.addToList(props.id);
        }
    };

    return (
        <Markup.GridCard style={props.style}>
            <ImagePreview images={props.images} href={`/adv/${props.id}`} isLoading={props.isLoading} />
            {props.id}
            <Markup.InfoContainer>
                {props.isLoading ? (
                    <Skeleton variant="rectangular" width="40%" height={24} />
                ) : (
                    <Markup.Price>
                        {price ? `${price.toLocaleString("ru")} ${currency}` : "Цена договорная"}
                    </Markup.Price>
                )}
                <Head isLoading={props.isLoading} id={props.id} title={props.title} />

                <Markup.Footer>
                    {props.isLoading ? (
                        <Skeleton variant="rectangular" width="60%" height={24} />
                    ) : (
                        <Markup.SubText>{date}</Markup.SubText>
                    )}

                    {props.isWishlistButtonVisible &&
                        (props.isLoading ? (
                            <Skeleton variant="rounded" width="24px" height="24px" />
                        ) : (
                            <Button onClick={handleWishListClick}>
                                {isInWishList ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                            </Button>
                        ))}
                </Markup.Footer>
            </Markup.InfoContainer>
        </Markup.GridCard>
    );
});
