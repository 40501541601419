import { Store } from "app/index";
import { UserAuth, WishListAuth } from "./brokers";

export class BrokerManager {
    private userAuth: UserAuth;
    private wishListAuth: WishListAuth;

    constructor(appState: Store.AppState) {
        this.userAuth = new UserAuth(appState);
        this.wishListAuth = new WishListAuth(appState);
    }
}
