import * as React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { Input } from "components";
import { useForm } from "./sign-up-form.hooks";
import * as Markup from "./sign-up-form.styles";

export const SignUpForm = observer(() => {
    const form = useForm();
    const { t } = useTranslation();

    return (
        <>
            <Markup.ErrorMessage>{form.errorMessage}</Markup.ErrorMessage>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Input
                        autoFocus
                        fullWidth
                        error={!!form.firstNameError}
                        helperText={form.firstNameError}
                        autoComplete="given-name"
                        label={t`firstName`}
                        value={form.firstName}
                        onChange={form.handleFirstNameChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        fullWidth
                        error={!!form.secondNameError}
                        helperText={form.secondNameError}
                        value={form.secondName}
                        label={t`secondName`}
                        name="secondName"
                        autoComplete="family-name"
                        onChange={form.handleSecondNameChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        fullWidth
                        error={!!form.loginError}
                        helperText={form.loginError}
                        value={form.email}
                        onChange={form.handleLoginChange}
                        label="Email"
                        autoComplete="email"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        error={!!form.passwordError}
                        helperText={form.passwordError}
                        fullWidth
                        value={form.password}
                        onChange={form.handlePasswordChange}
                        label={t`password`}
                        type="password"
                    />
                </Grid>
            </Grid>
            <LoadingButton variant="contained" loading={form.isLoading} onClick={form.handleSubmit}>
                {t`sign-up`}
            </LoadingButton>
        </>
    );
});
