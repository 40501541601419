import styled from "styled-components";

export const Header = styled.header`
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    position: fixed;
    width: 100vw;
    border-bottom: 1px solid #f5f5f5;
    background: #ffffff;
    overflow: hidden;
    z-index: 999;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    max-width: 1260px;
    width: 100%;
    height: 46px;
    margin: 0 auto;
`;

export const FlexContainer = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 8px;
`;
