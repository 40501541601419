import styled from "styled-components";
export const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 32px;
    transform: translate(-50%, -50%);
    max-width: 444px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    background: #ffffff;
    padding: 16px 24px;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`;
