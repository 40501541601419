import { makeAutoObservable, runInAction } from "mobx";
import { AdvertisementDomain } from "app-domain";
import { AdvertisementsViewHistoryService } from "./advertisements-view-history.types";

const emptyList: null[] = new Array(5).fill(null);

export class AdvertisementsViewHistory {
    public list: (AdvertisementDomain.Advertisement | null)[] = emptyList;
    public isListLoading = false;

    constructor(private service: AdvertisementsViewHistoryService) {
        makeAutoObservable(this);
    }

    public async loadHistory() {
        if (this.isListLoading) return;

        this.isListLoading = true;

        try {
            const result = await this.service.getViewHistory();

            runInAction(() => {
                this.list = result;
                this.isListLoading = false;
            });
        } catch {
            this.isListLoading = false;
        }
    }
}
