import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import MDTypography from "components/MDTypography";
import { AdvertisementDomain, DictionaryDomain } from "app-domain";
import * as Markup from "./info-card.styles";

interface Props {
    currencies: Map<string, DictionaryDomain.Currency>;
    title?: string;
    isLoading?: boolean;
    status?: AdvertisementDomain.Status;
    price?: AdvertisementDomain.Price;
    description?: NullableString;
}

export const InfoCard = (props: Props) => {
    const price = props.price?.value.start;
    const currency = props.price?.currencyType ? props.currencies?.get(props.price?.currencyType)?.symbol : "";

    return (
        <Markup.Container>
            {props.isLoading ? (
                <Skeleton style={{ borderRadius: "inherit" }} variant="rounded" height={20} width={50} />
            ) : (
                <Typography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
                    {price ? `${price.toLocaleString("ru")} ${currency}` : "Цена договорная"}
                </Typography>
            )}

            <div style={{ marginBottom: 8 }}>
                {props.isLoading ? (
                    <Skeleton style={{ borderRadius: "inherit" }} variant="rounded" height={22} width="70%" />
                ) : (
                    <Typography variant="h5" textTransform="capitalize">
                        <Markup.Title title={props.title}>{props.title}</Markup.Title>
                    </Typography>
                )}
            </div>
            <div>
                {props.isLoading ? (
                    <Skeleton style={{ borderRadius: "inherit" }} variant="rounded" height={40} width="90%" />
                ) : (
                    <MDTypography variant="button" fontWeight="light" color="text">
                        <Markup.Description title={props.description ?? ""}>{props.description}</Markup.Description>
                    </MDTypography>
                )}
            </div>
        </Markup.Container>
    );
};
