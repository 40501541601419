import React from "react";
import { observer } from "mobx-react-lite";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import * as MuiStyles from "@mui/material/styles";
import { I18nextProvider } from "react-i18next";
import { Header, Auth } from "features";
import { Loader } from "components";
import * as UiKit from "uikit";
import theme from "assets/theme";
import { MaterialUIControllerProvider } from "context";
import i18n from "shared/i18n";
import { Routing } from "routing";
import * as Markup from "./app.styles";

export const App = observer(() => {
    return (
        <MaterialUIControllerProvider>
            <MuiStyles.ThemeProvider theme={theme}>
                <I18nextProvider i18n={i18n}>
                    <ThemeProvider theme={UiKit.theme}>
                        <BrowserRouter>
                            <Markup.Container>
                                <Header />
                                <React.Suspense fallback={<Loader style={{ flexGrow: 1 }} />}>
                                    <Routing />
                                    <Auth />
                                </React.Suspense>
                            </Markup.Container>
                        </BrowserRouter>
                    </ThemeProvider>
                </I18nextProvider>
            </MuiStyles.ThemeProvider>
        </MaterialUIControllerProvider>
    );
});
