import styled from "styled-components";

export const Input = styled.input`
    background-color: transparent;
    border: none;
    outline: none;
    color: #001a34;
    padding-left: 8px;
    width: calc(100% - 0.5px);
    flex: 1;
    height: 100%;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 20px;
`;
