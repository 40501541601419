import React from "react";
import * as Markup from "./input-control.styles";

interface Props {
    value: string;
    onFocus: VoidFunction;
    onChange: ChangeEventHandler;
    onEnter: VoidFunction;
}

export const InputControl = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            props.onEnter();
        }
    };
    return (
        <Markup.Input
            ref={ref}
            placeholder="Искать на Tnetun"
            value={props.value}
            onFocus={props.onFocus}
            onKeyDown={handleKeyDown}
            onChange={props.onChange}
        />
    );
});
