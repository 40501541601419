import styled from "styled-components";

export const BulletContainer = styled.div`
    display: none;
    position: absolute;
    width: 90%;
    bottom: 0;
    left: 5%;
    height: 8px;
    gap: 4px;
`;
export const SwiperHover = styled.div`
    position: relative;
    width: 100%;

    &:hover ${BulletContainer} {
        display: flex;
    }
`;
export const Bullet = styled.div<{ active: boolean }>`
    width: 100%;
    height: 4px;
    background: ${({ active }) => (active ? "#6a3df7" : "#9e9e9e")};
`;
