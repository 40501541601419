import React from "react";
import { observer } from "mobx-react-lite";
import { Translation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import ViewListIcon from "@mui/icons-material/ViewList";
import ListItemIcon from "@mui/material/ListItemIcon";
import { usePrivateLinkClick } from "../user-menu.hooks";

interface Props {
    onClick?: VoidFunction;
}

export const MyAdvButton = observer((props: Props) => {
    const handleClick = usePrivateLinkClick("/profile/my", props.onClick);

    return (
        <MenuItem onClick={handleClick}>
            <ListItemIcon>
                <ViewListIcon fontSize="small" />
            </ListItemIcon>
            <Translation>{(t) => t("my-adv")}</Translation>
        </MenuItem>
    );
});
