import React from "react";
import { observer } from "mobx-react-lite";
import { Translation } from "react-i18next";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import { Store } from "app";

interface Props {
    onClick?: VoidFunction;
}

export const LogoutButton = observer((props: Props) => {
    const authStore = Store.Hooks.useAuthStore();

    const handleLogoutClick = React.useCallback(() => {
        authStore.logout();
        props.onClick?.();
    }, [authStore, props]);

    if (!authStore.isLogin) return null;

    return (
        <>
            <Divider />
            <MenuItem onClick={handleLogoutClick}>
                <ListItemIcon>
                    <Logout fontSize="small" />
                </ListItemIcon>
                <Translation>{(t) => t("logout")}</Translation>
            </MenuItem>
        </>
    );
});
