import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import Button from "@mui/material/Button";
import { Hooks } from "shared";
import { Store } from "app";
import { Notifications } from "features";
import { ToolsButton, Logo, WishList } from "./components";
import * as Markup from "./header.styles";
import * as Features from "../index";

export const Header = observer(() => {
    const authStore = Store.Hooks.useAuthStore();
    const authPath = Hooks.useAuthPath();
    const { t } = useTranslation();

    const handleSignInClick = React.useCallback(() => {
        authPath.handleOpenSignIn();
    }, [authPath]);

    return (
        <>
            <div style={{ height: 47 }} />
            <Markup.Header>
                <Markup.Container>
                    <Logo />
                    <Features.SearchControl />
                    <Markup.FlexContainer>
                        <WishList />
                        {authStore.isLogin && <Notifications />}
                        <ToolsButton />
                        {!authStore.isLogin && <Button onClick={handleSignInClick}>{t`sign-in_sign-up`}</Button>}
                    </Markup.FlexContainer>
                </Markup.Container>
            </Markup.Header>
        </>
    );
});
