import React from "react";
import { observer } from "mobx-react-lite";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import { Store } from "app";

const arrowDropDownIcon = <ArrowDropDownIcon />;

const categoryButtonStyles = {
    "backgroundColor": "#F0F0F0",
    "color": "#707f8d",
    "boxShadow": "unset",
    "height": 30,
    "margin": "2px 0 2px 2px",
    "borderRadius": "8px !important",

    "&:hover, &:focus": {
        backgroundColor: "#F0F0F0 !important",
        color: "primary.main",
        boxShadow: "unset",
    },
};

interface Props {
    onClick: VoidFunction;
}

export const CategorySelectModal = observer((props: Props) => {
    const store = Store.Hooks.useAdvertisementList();
    const dictionary = Store.Hooks.useDictionary();

    const activeCategory = dictionary.advertisementCategories.find(
        (item) => item.id === store.searchController.category
    );

    return (
        <Button
            size="small"
            variant="contained"
            sx={categoryButtonStyles}
            endIcon={arrowDropDownIcon}
            onClick={props.onClick}
        >
            {activeCategory?.displayName ?? "Все"}
        </Button>
    );
});
