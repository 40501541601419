import { observer } from "mobx-react-lite";
import { Store } from "app";
import { MediaCard, InfoCard, InfoCardProps } from "./components";
import * as Markup from "./advertment-card.styles";

interface Props extends Omit<InfoCardProps, "currencies"> {
    route?: string;
    id?: string;
    image?: string;
}
export const AdvertisementCard = observer((props: Props) => {
    const dictionary = Store.Hooks.useDictionary();

    return (
        <Markup.Container>
            <MediaCard title={props.title} route={props.route} image={props.image} isLoading={props.isLoading} />
            <InfoCard
                currencies={dictionary.currencies}
                title={props.title}
                isLoading={props.isLoading}
                status={props.status}
                price={props.price}
                description={props.description}
            />
        </Markup.Container>
    );
});
