import { OverviewCard } from "components";
import RealEstateBuy from "assets/real-estate-buy.png";
import RealEstateNewBuilding from "assets/real-estate-new-building.png";
import RealEstateHome from "assets/real-estate-home.png";
import RealEstateDailyRent from "assets/real-estate-daily-rent.png";
import RealEstateLongRent from "assets/real-estate-long-rent.png";
import * as Markup from "./realty-overview.styles";

export const RealtyOverview = () => {
    return (
        <Markup.Container>
            <Markup.List>
                <OverviewCard path="#" title="Купить квартиру" icon={RealEstateBuy} bg="#e8ffff" />
                <OverviewCard path="#" title="Выбрать новостройку" icon={RealEstateNewBuilding} bg="#ebfde6" />
                <OverviewCard path="#" title="Купить дом или участок" icon={RealEstateHome} bg="#f9f2ff" />
                <OverviewCard path="#" title="Снять надолго" icon={RealEstateLongRent} bg="#cef5d9" />
                <OverviewCard path="#" title="Выбрать новостройку" icon={RealEstateDailyRent} bg="#f2f5f7" />
            </Markup.List>
        </Markup.Container>
    );
};
