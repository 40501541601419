import React from "react";
import Skeleton from "@mui/material/Skeleton";
import * as Markup from "./head.styles";

interface Props {
    isLoading: boolean;
    id?: string;
    title?: string;
}

export const Head = React.memo((props: Props) => {
    if (props.isLoading) return <Skeleton variant="rectangular" width="80%" height={30} />;

    return (
        <Markup.InfoHead>
            <Markup.Link target="_blank" href={`/adv/${props.id}`} rel="noreferrer">
                <Markup.Title>{props.title}</Markup.Title>
            </Markup.Link>
        </Markup.InfoHead>
    );
});
