import Cookie from "js-cookie";
import { Api } from "api";
import { AuthAdapterImpl } from "./auth.types";

const authApi = Api.apiRegistry.authAPI;
const userApi = Api.apiRegistry.userAPI;

export class AuthAdapter implements AuthAdapterImpl {
    public async login(form: Api.Types.LoginForm) {
        const result = await authApi.login(form);

        if (result.value.isOk) {
            Cookie.set("access-token", result.value.data.token);
            Cookie.set("uid", result.value.data.userId);
        }

        return result;
    }

    public async register(form: Api.Types.RegistrationForm): Promise<Api.Auth.Types.RegisterResponse> {
        const result = await authApi.register(form);

        if (result.value.isOk) {
            Cookie.set("access-token", result.value.data.token);
            Cookie.set("uid", result.value.data.userId);
        }

        return result;
    }

    public verify(form: Api.Types.VerifyForm): Promise<Api.Auth.Types.VerifyResponse> {
        return authApi.verify(form);
    }

    public getUser(id: string) {
        return userApi.getUser(id);
    }
}
