import Button from "@mui/material/Button";
import { Api } from "api";
export const VerifyForm = () => {
    const handleClick = () => {
        Api.apiRegistry.authAPI.resendVerification();
    };

    return (
        <div>
            <div>Подтвердите ваш email</div>
            <div>Если вы еще не получили сообщение перезапросите</div>
            <Button onClick={handleClick}>Перезапросить</Button>
        </div>
    );
};
