import React from "react";
import { observer } from "mobx-react-lite";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import { LanguageSelect } from "features";
import { NewAdvButton } from "./new-adv-button";
import { MyAdvButton } from "./my-adv-button";
import { SettingsButton } from "./settings-button";
import { ProfileButton } from "./profile-button";
import { LogoutButton } from "./logout-button";
import { paperProps } from "./user-menu.constants";

interface Props {
    anchor: Element;
    handleClose: VoidFunction;
}

export const UserMenu = observer((props: Props) => {
    const { anchor, handleClose } = props;

    return (
        <Menu
            anchorEl={anchor}
            open={!!anchor}
            onClose={handleClose}
            PaperProps={paperProps}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
            <ProfileButton onClick={handleClose} />

            <Divider />

            <NewAdvButton onClick={handleClose} />
            <MyAdvButton onClick={handleClose} />
            <SettingsButton onClick={handleClose} />

            <Divider />

            <LanguageSelect />
            <LogoutButton onClick={handleClose} />
        </Menu>
    );
});
