import { appEnv } from "shared";
import { Auth, Advertisement, User, Dictionary, WishList, View, SearchHistory, PaidBlock } from "./endpoints";

export const apiRegistry = {
    dictionaryAPI: new Dictionary.DictionaryAPI(appEnv.baseApi),
    authAPI: new Auth.AuthApi(appEnv.baseApi),
    userAPI: new User.UserAPI(appEnv.baseApi),
    advertisementAPI: new Advertisement.AdvertisementAPI(appEnv.baseApi),
    wishListAPI: new WishList.WishListAPI(appEnv.baseApi),
    searchHistoryAPI: new SearchHistory.SearchHistoryAPI(appEnv.baseApi),
    viewAPI: new View.ViewAPI(appEnv.baseApi),
    paidBlockAPI: new PaidBlock.PaidBlockAPI(appEnv.baseApi),
};
