import * as React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Input } from "components";
import { useForm } from "./sign-in-form.hooks";
import * as Markup from "./sign-in-form.styles";

export const SignInForm = observer(() => {
    const form = useForm();
    const { t } = useTranslation();

    return (
        <>
            <Markup.ErrorMessage>{form.errorMessage}</Markup.ErrorMessage>
            <Input
                fullWidth
                label="Email"
                name="email"
                autoComplete="email"
                required
                error={!!form.loginError}
                helperText={form.loginError}
                autoFocus
                onChange={form.handleLoginChange}
            />
            <Input
                fullWidth
                required
                name="password"
                label={t`password`}
                type="password"
                autoComplete="password"
                error={!!form.passwordError}
                helperText={form.passwordError}
                onChange={form.handlePasswordChange}
            />
            <FormControlLabel control={<Checkbox />} label={t`remember-me`} />
            <LoadingButton loading={form.isLoading} variant="contained" onClick={form.handleSubmit}>
                {t`sign-in`}
            </LoadingButton>
        </>
    );
});
