import React from "react";
import Skeleton from "@mui/material/Skeleton";
// import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import * as Markup from "./media-card.styles";

interface Props {
    isWishListIconHidden?: boolean;
    isInWishList?: boolean;
    isLoading?: boolean;
    title?: string;
    image?: string;
    onWishlistClick?: (event: React.MouseEvent) => void;
}

export const MediaCard = (props: Props) => {
    const icon = props.isInWishList ? <FavoriteIcon fontSize="small" /> : <FavoriteBorderIcon fontSize="small" />;

    return (
        <div>
            {props.isLoading ? (
                <Skeleton style={{ borderRadius: "inherit" }} variant="rounded" height={180} width="100%" />
            ) : (
                // <CardMedia src={props.image} loading="lazy" component="img" title={props.title} sx={cardMediaStyles} />
                <Markup.Image src={props.image} />
            )}
            {!props.isLoading && !props.isWishListIconHidden && (
                <IconButton
                    size="small"
                    sx={{
                        "position": "absolute",
                        "top": 4,
                        "right": 4,
                        "backgroundColor": "rgba(0, 0, 0, 0.1)",
                        "color": props.isInWishList ? "info.main" : "#ffffff",

                        "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.2)",
                        },
                    }}
                    onClick={props.onWishlistClick}>
                    {icon}
                </IconButton>
            )}
        </div>
    );
};
