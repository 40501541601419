import Cookie from "js-cookie";
import { BaseAPI } from "api/utils";
import { IPagedCollection } from "api/types";
import { ViewDomain } from "app-domain";

export class ViewAPI extends BaseAPI {
    public getGeneralView = (): Promise<IPagedCollection<ViewDomain.GeneralView>> => {
        const url = this.buildUrl("/general");

        return this.request({ url, token: Cookie.get("access-token") });
    };

    protected buildUrl(endpoint: string = ""): string {
        return super.buildUrl(`/trade/view${endpoint}`);
    }
}
