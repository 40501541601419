import React from "react";
import Box from "@mui/material/Box";
import { CategoryContent } from "./category-content";
import { InputContent } from "./input-content";
import { boxStyle } from "./search-control-modal.constants";

export type ContentType = "category" | "search";

interface Props {
    contentType: ContentType;
    onClose: VoidFunction;
    width: number;
}

export const SearchControlModal = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    return (
        <Box sx={boxStyle} ref={ref} style={{ width: props.width }}>
            {props.contentType === "category" && <CategoryContent closeModal={props.onClose} />}
            {props.contentType === "search" && <InputContent />}
        </Box>
    );
});
