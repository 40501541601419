import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import * as Markup from "./loader.styles";

interface Props {
    style?: React.CSSProperties;
    className?: string;
}

export const Loader = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
    <Markup.Container ref={ref} className={props.className} style={props.style}>
        <CircularProgress color="info" />
    </Markup.Container>
));
