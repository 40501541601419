import { Translation } from "react-i18next";
import { Status } from "../enums";

export const statusColorsMap = {
    [Status.Active]: {
        background: "#25A109",
        label: <Translation>{(t) => t("adv-status.active")}</Translation>,
        color: "#FFFFFF",
    },
    [Status.Moderation]: {
        background: "#0B1054",
        label: <Translation>{(t) => t("adv-status.moderation")}</Translation>,
        color: "#FFFFFF",
    },
    [Status.Draft]: {
        background: "orange",
        label: <Translation>{(t) => t("adv-status.draft")}</Translation>,
        color: "#323232",
    },
    [Status.Archived]: {
        background: "#ececec",
        label: <Translation>{(t) => t("adv-status.archived")}</Translation>,
        color: "#323232",
    },
};

export const statusColorsList = Object.entries(statusColorsMap);
