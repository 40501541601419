// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import { palette } from "assets/theme/base/palette";

const { size } = typography;

const dialogContentText = {
    styleOverrides: {
        root: {
            fontSize: size.md,
            color: palette.text.main,
        },
    },
};

export default dialogContentText;
