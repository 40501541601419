import styled from "styled-components";

export const ImageContainer = styled.div`
    width: 100%;
    height: 180px;
    background-color: grey;
    border-radius: 12px;
`;

export const Link = styled.a`
    text-decoration: none;
`;

export const ImageWrapper = styled.div`
    height: 180px;
    background-color: grey;
`;

export const AdaptiveImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    object-position: center;
    pointer-events: none;
    border-radius: 12px;
`;
