import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Link = styled(NavLink)<{ icon: string; bg: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    min-height: 120px;
    padding: 76px 16px 16px;
    text-decoration: none;
    white-space: pre-wrap;
    border-radius: 12px;
    min-width: 236px;
    width: auto;
    color: var(--dc-color-afro, #242629);
    background-color: ${({ bg }) => bg};
    flex-wrap: nowrap;
    user-select: none;

    &:before {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
        height: 40px;
        left: 16px;
        margin-bottom: 8px;
        position: absolute;
        top: 16px;
        width: 40px;
        background-image: ${({ icon }) => `url(${icon})`};
    }
`;
