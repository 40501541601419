import React from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

interface Props {
    onChange: (files: FileList) => void;
}

export const AddImageButton = React.memo((props: Props) => {
    const handleFileChange: ChangeEventHandler = React.useCallback(
        (event) => {
            if (!event.target.files) return;
            props.onChange(event.target.files);
        },
        [props]
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        document.getElementById("upload-file-button")?.click();
    };

    return (
        <form>
            <label htmlFor="upload-file-button">
                <input
                    style={{
                        display: "none",
                    }}
                    multiple
                    type="file"
                    accept="image/*"
                    id="upload-file-button"
                    onChange={handleFileChange}
                />
                <button
                    id="upload-file-button"
                    onClick={handleClick}
                    type="submit"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        border: "none",
                        alignItems: "center",
                        backgroundColor: "rgba(27, 108, 108, 0.1)",
                        width: 150,
                        height: 150,
                        borderRadius: 8,
                        cursor: "pointer",
                    }}
                >
                    <AddAPhotoIcon />
                    <div>Добавить фото</div>
                </button>
            </label>
        </form>
    );
});
