import { Store } from "app";
import { useSearchControlContext } from "./search-control.context";

export const useSearchControl = () => {
    const { inputRef, modalRef, containerRef } = useSearchControlContext();
    const { searchController, submit } = Store.Hooks.useAdvertisementList();

    const handleClose = () => {
        inputRef?.current?.blur();
        searchController.setModalContentType(null);
    };

    const handleCategoryClick = () => {
        searchController.setModalContentType("category");
    };

    const handleInputFocus = () => {
        searchController.setModalContentType("search");
        inputRef?.current?.focus();
    };

    const handleSubmit = () => {
        submit();
        handleClose();
    };

    if (modalRef?.current && containerRef?.current) {
        modalRef.current.style.maxWidth = `${containerRef?.current.offsetWidth}px`;
    }

    return {
        modalContentType: searchController.modalContentType,
        searchString: searchController.searchString,
        onTextChange: searchController.onTextChange,
        handleCategoryClick,
        handleInputFocus,
        handleSubmit,
        handleClose,
    };
};
