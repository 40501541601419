import { makeAutoObservable, runInAction } from "mobx";
import { AdvertisementDomain } from "app-domain";
import { WishListAdapterImpl } from "./wish-list.types";

const emptyList: null[] = new Array(20).fill(null);

export class WishList {
    public isListLoading = false;
    public isRemoveLoading = false;
    public isAddLoading = false;
    public isListInitialized = false;
    public list: (AdvertisementDomain.Advertisement | null)[] = emptyList;
    public availableIds: Set<string> = new Set();

    constructor(private service: WishListAdapterImpl) {
        makeAutoObservable(this);
    }

    public async loadList() {
        if (this.isListInitialized || this.isListLoading) return;

        this.isListLoading = true;

        try {
            const result = await this.service.getWishList();
            const ids = result.map((item) => item.id);

            runInAction(() => {
                this.availableIds = new Set<string>(ids);
                this.list = result;
                this.isListLoading = false;
                this.isListInitialized = true;
            });
        } catch (error) {
            runInAction(() => {
                this.isListLoading = false;
                this.list = [];
            });
        }
    }

    public async addToList(id: string) {
        this.isAddLoading = true;

        try {
            await this.service.addToWishList(id);

            runInAction(() => {
                this.isListInitialized = false;
                this.isAddLoading = false;
                this.availableIds.add(id);
            });
        } catch (error) {
            runInAction(() => {
                this.isAddLoading = false;
            });
        }
    }

    public async removeFormList(id: string) {
        this.isRemoveLoading = true;

        try {
            await this.service.removeFromWishList(id);

            runInAction(() => {
                this.isListInitialized = false;
                this.isRemoveLoading = false;
                this.availableIds.delete(id);
            });
        } catch (error) {
            runInAction(() => {
                this.isRemoveLoading = false;
            });
        }
    }

    public reset() {
        this.list = [];
        this.availableIds = new Set();
        this.isListLoading = false;
        this.isRemoveLoading = false;
        this.isAddLoading = false;
        this.isListInitialized = false;
    }
}
