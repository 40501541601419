import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    cursor: pointer;

    &:hover {
        background-color: #e7ebf0;
    }
`;

export const Text = styled.span`
    width: 100%;
    height: 100%;
`;
