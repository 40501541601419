import React from "react";
import * as Markup from "./adaptive-image.styles";

interface Props {
    src: string;
    className?: string;
}

export const AdaptiveImage = React.memo((props: Props) => (
    <Markup.Wrapper className={props.className}>
        <Markup.Container src={props.src} />
        <Markup.Image alt=" " loading="lazy" src={props.src} />
    </Markup.Wrapper>
));
