import React from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "app";

export const useForm = () => {
    const navigate = useNavigate();
    const authStore = Store.Hooks.useAuthStore();
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [loginError, setLoginError] = React.useState("");
    const [passwordError, setPasswordError] = React.useState("");

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        let hasError = false;

        if (!email.trim()) {
            hasError = true;
            setLoginError("Обязательное поле");
        }

        if (!password.trim()) {
            hasError = true;

            setPasswordError("Обязательное поле");
        }

        if (hasError) return;

        authStore
            .login({
                email,
                password,
            })
            .then(() => {
                if (!authStore.isLogin) return;

                const query = new URLSearchParams(window.location.search);
                const redirect = query.get("redirect");

                if (redirect) {
                    navigate(redirect);
                } else {
                    query.delete("auth");
                    window.location.search = query.toString();
                }
            });
    };

    const handleLoginChange: ChangeEventHandler = (event) => {
        if (loginError) {
            setLoginError("");
        }
        setEmail(event.target.value);
    };
    const handlePasswordChange: ChangeEventHandler = (event) => {
        if (passwordError) {
            setPasswordError("");
        }
        setPassword(event.target.value);
    };

    return {
        errorMessage: authStore.loginErrorMessage,
        isLoading: authStore.isLoading,
        email,
        password,
        loginError,
        passwordError,
        handleSubmit,
        handleLoginChange,
        handlePasswordChange,
    };
};
