import styled from "styled-components";
import MuiButton from "@mui/material/Button";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Info = styled.div`
    padding: 40px 0;
    margin: auto;
    width: 230px;
    text-align: center;
`;

export const Image = styled.img`
    width: 192px;
    height: 192px;
`;

export const Title = styled.p`
    font-size: 16px;
    margin: 16px 0;
`;
export const Description = styled.p`
    font-size: 14px;
    margin: 16px 0;
`;

export const Button = styled(MuiButton)`
    padding: 4px 16px;
    border-radius: 16px;
`;
