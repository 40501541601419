import React from "react";
import { observer } from "mobx-react-lite";
import InfiniteScroll from "react-infinite-scroller";
import { Store } from "app";
import { Loader } from "components";
import { AdvCard } from "features";
import { appEnv } from "shared";
import * as Markup from "./advertisements-list.styles";

interface Props {
    anchor?: HTMLElement | null;
}

const columnsPerRow = 4;
const rowHeight = 308;
const threshold = rowHeight * 2;

export const AdvertisementsList = observer((props: Props) => {
    const gridContainer = React.useRef<HTMLDivElement>(null);
    const advertisementStore = Store.Hooks.useAdvertisementList();
    const amountRows = Math.ceil(advertisementStore.list.length / columnsPerRow);

    React.useEffect(() => {
        const grid = gridContainer.current;
        const anchor = props.anchor;

        if (!anchor || !grid) return;
        const handleScroll = (event: Event) => {
            const currentTarget = event.currentTarget as HTMLElement;

            if (!currentTarget) return;

            grid.style.height = `${amountRows * rowHeight}px`;
        };

        anchor.addEventListener("scroll", handleScroll);

        return () => anchor.removeEventListener("scroll", handleScroll);
    }, [amountRows, props.anchor]);

    const style = {
        height: "100%",
        width: "100%",
    };

    if (!advertisementStore.list.length) return null;

    return (
        <InfiniteScroll
            style={{ width: "100%", height: "100%" }}
            threshold={threshold}
            loadMore={advertisementStore.loadMore}
            hasMore={advertisementStore.hasMore}
            loader={<Loader key="0" />}
            useWindow={false}
            getScrollParent={() => props.anchor ?? null}>
            <Markup.Container ref={gridContainer} style={style}>
                {advertisementStore.list.map((advertisement, index) => (
                    <AdvCard
                        route={`/adv/${advertisement?.id}`}
                        key={advertisement?.id || index}
                        isLoading={!advertisement}
                        id={advertisement?.id}
                        title={advertisement?.title}
                        image={`${appEnv.cdn}/${advertisement?.images?.[0]}`}
                        price={advertisement?.price}
                    />
                ))}
                <div style={{ width: 250 }} />
                <div style={{ width: 250 }} />
                <div style={{ width: 250 }} />
            </Markup.Container>
        </InfiniteScroll>
    );
});
