// Material Dashboard 2 React helper functions
import { rgba } from "./rgba";

export const gradientChartLine = (chart: HTMLCanvasElement, color: string, opacity = 0.2) => {
    const ctx = chart.getContext("2d");
    if (!ctx) {
        throw new Error("canvas not provided");
    }
    const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);
    const primaryColor = rgba(color, opacity).toString();

    gradientStroke.addColorStop(1, primaryColor);
    gradientStroke.addColorStop(0.2, "rgba(72, 72, 176, 0.0)");
    gradientStroke.addColorStop(0, "rgba(203, 12, 159, 0)");

    return gradientStroke;
};
