import { makeAutoObservable } from "mobx";

export class LocalStorageManager {
    private _list: Set<string> | null = null;

    constructor(private storageKey: string) {
        makeAutoObservable(this);
    }

    public get list(): string[] {
        if (this._list === null) {
            const storage = window.localStorage.getItem(this.storageKey);

            if (storage) {
                this._list = new Set(this.parseStorage(storage));
            } else {
                window.localStorage.setItem(this.storageKey, "[]");
                this._list = new Set();
            }
        }

        return Array.from(this._list).reverse();
    }

    public addRecord(value: string) {
        if (!this._list || !value) return;

        this._list.add(value);
        this._list = new Set(Array.from(this._list));
        this.updateStorage();
    }

    public removeRecord(value: string) {
        if (!this._list) return;

        this._list.delete(value);

        this.updateStorage();
    }

    public parseStorage(data: string): string[] {
        try {
            const result = JSON.parse(data);
            return Array.isArray(result) ? result : [];
        } catch {
            return [];
        }
    }

    private updateStorage() {
        if (!this._list) return;

        window.localStorage.setItem(this.storageKey, JSON.stringify(Array.from(this._list)));
    }
}
