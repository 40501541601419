export interface RemoveRecordParams {
    searchString: string;
}

export interface SearchControllerServiceImpl {
    getHistory(): Promise<string[]>;
    getVariants(value: string, signal: AbortSignal): Promise<string[]>;
    removeRecord(params: RemoveRecordParams): Promise<string[]>;
}

export enum Mode {
    Local,
    Remote,
}
