import { makeAutoObservable, runInAction } from "mobx";
import { DictionaryDomain } from "app-domain";
import { LocalStorageManager } from "./local-storage-manager";
import { Mode, SearchControllerServiceImpl } from "./serach-controller.types";

type ModalContentType = "category" | "search";

export class SearchController {
    public mode: Mode = Mode.Local;
    public isLoadHistoryLoading = false;
    public isRemoveRecordLoading = false;
    public searchString = "";
    public category: DictionaryDomain.AdvertisementCategoryCode | null = null;
    public readonly modes = Mode;
    public modalContentType: ModalContentType | null = null;
    public variants: string[] = [];
    private _searchHistory: string[] = [];
    private readonly storageManager = new LocalStorageManager("S-h-7R4V1G");
    private loadVariantsAbortController = new AbortController();

    constructor(private service: SearchControllerServiceImpl) {
        makeAutoObservable(this);
    }

    public setModalContentType(value: ModalContentType | null) {
        this.modalContentType = value;
    }

    public get searchHistory(): string[] {
        const list = this.mode === Mode.Local ? this.storageManager.list : this._searchHistory;

        return list.filter((record) => record.toLocaleLowerCase().includes(this.searchString.toLocaleLowerCase()));
    }

    public onRecordSelect = (value: string) => {
        this.searchString = value;
    };

    public onModeChange(mode: Mode) {
        this.mode = mode;

        if (mode === Mode.Remote) {
            this.loadHistory();
        }
    }

    public setCategory(value: DictionaryDomain.AdvertisementCategoryCode | null) {
        this.category = value;
    }

    public onTextChange: ChangeEventHandler = (event) => {
        this.searchString = event.target.value;

        this.loadVariants();
    };

    public handleSearchStringChange = (value?: string) => {
        this.searchString = value ?? "";
    };

    public removeRecord = async (searchString: string) => {
        if (this.mode === Mode.Local) {
            return this.storageManager.removeRecord(searchString);
        }

        this.isRemoveRecordLoading = true;

        try {
            this._searchHistory = await this.service.removeRecord({ searchString });
            this.isRemoveRecordLoading = false;
        } catch {
            this.isRemoveRecordLoading = false;
        }
    };

    public build() {
        const searchString = this.searchString.trim();

        if (this.mode === Mode.Local) {
            this.storageManager.addRecord(searchString);
        } else {
            if (this.searchString) {
                this._searchHistory = this._searchHistory.filter((item) => item !== searchString);
                this._searchHistory.unshift(this.searchString);
            }
        }

        return {
            category: this.category,
            searchString: this.searchString,
        };
    }

    private loadVariants() {
        if (this.searchString.length < 3) {
            runInAction(() => {
                this.variants = [];
            });
            return;
        }

        this.loadVariantsAbortController.abort();
        this.loadVariantsAbortController = new AbortController();

        this.service.getVariants(this.searchString, this.loadVariantsAbortController.signal).then((result) => {
            this.variants = result;
        });
    }

    private async loadHistory() {
        this.isLoadHistoryLoading = true;

        try {
            const result = await this.service.getHistory();

            runInAction(() => {
                this._searchHistory = result;
                this.isLoadHistoryLoading = true;
            });
        } catch (error) {
            runInAction(() => {
                this.isLoadHistoryLoading = true;
            });
        }
    }
}
