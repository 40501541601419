import * as React from "react";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";

interface Props {
    onSignInClick: VoidFunction;
}

export const SignUpFooter = React.memo((props: Props) => {
    const { t } = useTranslation();

    return (
        <Grid container justifyContent="flex-end">
            <Grid item>
                <Link style={{ cursor: "pointer" }} onClick={props.onSignInClick} variant="body2">
                    {t`sign-up-footer-text`}
                </Link>
            </Grid>
        </Grid>
    );
});
