import styled from "styled-components";

export const LanguageIcon = styled.img`
    width: 24px;
    height: 24px;
`;

export const Text = styled.span`
    margin-left: 8px;
`;
