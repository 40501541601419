import React, { memo, useRef, useMemo, useState, useCallback, useEffect, useLayoutEffect } from "react";
import { PrevNext } from "./prev-next";
import { CarouselSlide } from "./carousel-slide";
import { useDraggableScroll, useResize } from "./carousel.hooks";
import * as Markup from "./carousel.styles";

interface Props {
    gap?: number;
    padding?: string;
    isOverflowHidden?: boolean;
    target?: Element;
}

export const Carousel = memo((props: React.PropsWithChildren<Props>) => {
    const { gap, padding, isOverflowHidden } = props;
    const wrapperRef = useRef<Nullable<HTMLDivElement>>(null);
    const carouselRef = useRef<Nullable<HTMLDivElement>>(null);
    const currentPosition = useRef(0);
    const wrapperWidth = useResize(wrapperRef);
    const [containerWidth, setContainerWidth] = useState(0);

    const collection = useMemo(
        () => React.Children.map(props.children, (child) => <CarouselSlide>{child}</CarouselSlide>),
        [props.children]
    );

    const isSlidable = containerWidth >= wrapperWidth;

    useLayoutEffect(() => {
        if (!carouselRef.current) return;

        const width = carouselRef.current?.getBoundingClientRect().width ?? 0;

        setContainerWidth(width);
    }, [collection, containerWidth]);

    const handleNextClick = useCallback(() => {
        let next = currentPosition.current - wrapperWidth;
        currentPosition.current = next < wrapperWidth - containerWidth ? wrapperWidth - containerWidth : next;
        carouselRef.current!.style.transform = `translateX(${currentPosition.current}px)`;
    }, [wrapperWidth, containerWidth]);

    const handlePrevClick = useCallback(() => {
        const next = currentPosition.current + wrapperWidth;
        currentPosition.current = next > 0 ? 0 : next;
        carouselRef.current!.style.transform = `translateX(${currentPosition.current}px)`;
    }, [wrapperWidth]);

    useDraggableScroll({
        carouselRef,
        wrapperWidth,
        containerWidth,
        isDisabled: !isSlidable,
    });

    useEffect(() => {
        if (!isSlidable && !currentPosition.current) return;
        carouselRef.current!.style.transform = `translateX(0)`;
    }, [isSlidable]);

    useEffect(() => {
        const element = props.target?.getClientRects();
        const wrapper = wrapperRef.current?.getClientRects();

        if (!element || !wrapper) return;

        const moveToRight = element[0].left < wrapper[0].left;
        const moveToLeft = element[0].right > wrapper[0].right;

        if (moveToRight) {
            currentPosition.current = currentPosition.current + Math.abs(wrapper[0].left - element[0].left);
        }

        if (moveToLeft) {
            currentPosition.current = currentPosition.current - Math.abs(wrapper[0].right - element[0].right);
        }

        if (!carouselRef.current) return;

        carouselRef.current.style.transform = `translateX(${currentPosition.current}px)`;
    }, [props.target]);

    return (
        <Markup.Container>
            <Markup.ListContainerWrapper isOverflowHidden={isOverflowHidden} ref={wrapperRef}>
                <Markup.ListContainer padding={padding} gap={gap} ref={carouselRef}>
                    {collection}
                </Markup.ListContainer>
            </Markup.ListContainerWrapper>
            {isSlidable && <PrevNext onPrevClick={handlePrevClick} onNextClick={handleNextClick} />}
        </Markup.Container>
    );
});
