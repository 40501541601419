import { forwardRef } from "react";
import { BoxProps } from "@mui/material/Box";
import MDBoxRoot, { OwnerState } from "components/MDBox/MDBoxRoot";

const MDBox = forwardRef<HTMLDivElement, BoxProps & Partial<OwnerState>>((props, ref) => {
    const {
        variant = "contained",
        bgColor = "transparent",
        color = "dark",
        opacity = 1,
        borderRadius = "none",
        shadow = "none",
        coloredShadow = "none",
        ...rest
    } = props;

    return (
        <MDBoxRoot
            {...rest}
            ref={ref}
            ownerState={{
                variant,
                bgColor,
                color,
                opacity,
                borderRadius,
                shadow,
                coloredShadow,
            }}
        />
    );
});

export default MDBox;
