// Material Dashboard 2 React Base Styles
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

// Material Dashboard 2 React Helper Functions
import { pxToRem } from "assets/theme/functions/pxToRem";

const { fontWeightBold, size } = typography;
const { borderRadius } = borders;

const root = {
    "display": "inline-flex",
    "justifyContent": "center",
    "alignItems": "center",
    "fontSize": size.xs,
    "fontWeight": fontWeightBold,
    "borderRadius": borderRadius.xl,
    "padding": `${pxToRem(6.302)} ${pxToRem(16.604)}`,
    "lineHeight": "24px",
    "textAlign": "center",
    "textTransform": "none",
    "userSelect": "none",
    "backgroundSize": "150% !important",
    "backgroundPositionX": "25% !important",
    "transition": "all 150ms ease-in",
    "whiteSpace": "nowrap",
    "&:disabled": {
        pointerEvent: "none",
        opacity: 0.65,
    },

    "& .material-icons": {
        fontSize: pxToRem(15),
        marginTop: pxToRem(-2),
    },
};

export default root;
