import React from "react";
import { observer } from "mobx-react-lite";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import { Store } from "app";
import { usePrivateLinkClick } from "../user-menu.hooks";

interface Props {
    onClick?: VoidFunction;
}

export const ProfileButton = observer((props: Props) => {
    const authStore = Store.Hooks.useAuthStore();
    const userStore = Store.Hooks.useUserStore();

    const handleClick = usePrivateLinkClick("/profile", props.onClick);

    return (
        <MenuItem onClick={handleClick}>
            <Avatar />
            {authStore.isLogin && !!userStore.profile ? userStore.profile?.firstName : "Профиль пользователя"}
        </MenuItem>
    );
});
