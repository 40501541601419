import Cookie from "js-cookie";
import { makeAutoObservable, runInAction } from "mobx";
import { Api } from "api";
import { AuthAdapterImpl } from "./auth.types";

export class Auth {
    public loginErrorMessage = "";
    public registerErrorMessage = "";
    public isLogin = !!Cookie.get("access-token");
    public isLoading = false;
    public userId: NullableString = Cookie.get("uid") || null;

    constructor(private service: AuthAdapterImpl) {
        makeAutoObservable(this);
    }

    public async login(form: Api.Types.LoginForm) {
        runInAction(() => {
            this.isLoading = true;
        });

        try {
            const result = await this.service.login(form);

            runInAction(() => {
                this.isLoading = false;
                this.isLogin = result.value.isOk;

                if (result.value.isOk) {
                    this.userId = result.value.data.userId;
                    this.loginErrorMessage = "";
                } else {
                    this.loginErrorMessage = "Не верный Email или пароль";
                }
            });
        } catch {
            runInAction(() => {
                this.isLoading = false;
                this.isLogin = false;
                this.loginErrorMessage = "Не верный Email или пароль";
            });
        }
    }

    public logout() {
        Cookie.remove("access-token");
        Cookie.remove("uid");

        this.isLogin = false;
        this.userId = null;
    }

    public async registration(form: Api.Types.RegistrationForm) {
        if (this.isLoading) return;

        runInAction(() => {
            this.isLoading = true;
            this.registerErrorMessage = "";
        });

        try {
            const result = await this.service.register(form);

            runInAction(() => {
                this.isLoading = false;
                this.isLogin = result.value.isOk;

                if (result.value.isOk) {
                    this.userId = result.value.data.userId;
                } else {
                    this.registerErrorMessage = "Что-то не так";
                }
            });
        } catch {
            runInAction(() => {
                this.isLoading = false;
                this.isLogin = false;
                this.registerErrorMessage = "Что-то не так";
            });
        }
    }
}
