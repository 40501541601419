/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React Base Styles
import { palette } from "assets/theme/base/palette";
import borders from "assets/theme/base/borders";
import typography from "assets/theme/base/typography";

// Material Dashboard 2 React helper functions
import { pxToRem } from "assets/theme/functions";

const { inputBorderColor, info, grey, transparent } = palette;
const { borderRadius } = borders;
const { size } = typography;

const inputOutlined = {
    styleOverrides: {
        root: {
            "backgroundColor": transparent.main,
            "fontSize": size.sm,
            "borderRadius": borderRadius.md,

            "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: inputBorderColor,
            },

            "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: info.main,
                },
            },
        },

        notchedOutline: {
            borderColor: inputBorderColor,
        },

        input: {
            color: grey[700],
            padding: pxToRem(12),
            backgroundColor: transparent.main,
        },

        inputSizeSmall: {
            fontSize: size.xs,
            padding: pxToRem(10),
        },

        multiline: {
            color: grey[700],
            padding: 0,
        },
    },
};

export default inputOutlined;
