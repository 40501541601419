export class LanguageStorageManager {
    public language: string;
    constructor(private storageKey: string) {
        const storageLanguage = localStorage.getItem(storageKey);
        const navigatorLanguage = window.navigator.language.split("-")[0];

        if (!storageLanguage) {
            localStorage.setItem(this.storageKey, navigatorLanguage);
        }

        this.language = storageLanguage || navigatorLanguage;
    }

    public setLanguage(value: string) {
        localStorage.setItem(this.storageKey, value);
        window.location.reload();
    }
}
