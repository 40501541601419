import React from "react";
import { observer } from "mobx-react-lite";
import Typography from "@mui/material/Typography";
import { Store } from "app";
import { appEnv } from "shared";
import { Carousel } from "components";
import { AdvCard } from "features";
import MDBox from "components/MDBox";
import * as Markup from "./advertisements-view-history.styles";
import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";

export const AdvertisementsViewHistory = observer(() => {
    const store = Store.Hooks.useAdvViewHistory();

    const { t } = useTranslation();

    React.useEffect(() => {
        store.loadHistory();
    }, [store]);

    if (!store.isListLoading && store.list.length === 0) return null;

    return (
        <Markup.Container>
            {store.list[0] === null || store.isListLoading ? (
                <Skeleton variant="rectangular" width="200px" height="22px" />
            ) : (
                <Typography variant="h4">{t`you-are-interested`}</Typography>
            )}

            <MDBox style={{ overflow: "hidden" }}>
                <Carousel gap={8} padding="2px 0">
                    {store.list.map((adv, index) => {
                        const image = adv?.images[0] ? `${appEnv.cdn}/${adv?.images[0]}` : undefined;
                        return (
                            <AdvCard
                                key={adv?.id ?? index}
                                isLoading={store.isListLoading}
                                id={adv?.id}
                                status={adv?.status}
                                title={adv?.title}
                                description={adv?.description}
                                image={image}
                                price={adv?.price}
                                route={`/adv/${adv?.id}`}
                            />
                        );
                    })}
                </Carousel>
            </MDBox>
        </Markup.Container>
    );
});
