import { useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SidenavCollapse from "./SidenavCollapse";
import SidenavRoot from "./SidenavRoot";
import { useMaterialUIController, setMiniSidenav, setTransparentSidenav, setWhiteSidenav } from "context";

export const Sidenav = ({ color, routes, ...rest }) => {
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
    const location = useLocation();
    const collapseName = location.pathname;

    let textColor = "white";

    if (transparentSidenav || (whiteSidenav && !darkMode)) {
        textColor = "dark";
    } else if (whiteSidenav && darkMode) {
        textColor = "inherit";
    }

    const closeSidenav = () => setMiniSidenav(dispatch, true);

    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200);
            setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
            setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
        }

        /**
         The event listener that's calling the handleMiniSidenav function when resizing the window.
         */
        window.addEventListener("resize", handleMiniSidenav);

        // Call the handleMiniSidenav function to set the state with the initial value.
        handleMiniSidenav();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleMiniSidenav);
    }, [transparentSidenav, dispatch, location, whiteSidenav]);

    // Render all the routes from the routes.js (All the visible items on the sidenav)
    const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
        let returnValue;

        if (type === "collapse") {
            returnValue = href ? (
                <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
                    <SidenavCollapse name={name} icon={icon} active={key === collapseName} noCollapse={noCollapse} />
                </Link>
            ) : (
                <NavLink style={{ textDecoration: "none" }} key={key} to={route}>
                    {({ isActive }) => <SidenavCollapse name={name} icon={icon} active={isActive} />}
                </NavLink>
            );
        } else if (type === "title") {
            returnValue = (
                <MDTypography
                    key={key}
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="uppercase"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                >
                    {title}
                </MDTypography>
            );
        } else if (type === "divider") {
            returnValue = (
                <Divider
                    key={key}
                    light={
                        (!darkMode && !whiteSidenav && !transparentSidenav) ||
                        (darkMode && !transparentSidenav && whiteSidenav)
                    }
                />
            );
        }

        return returnValue;
    });

    return (
        <SidenavRoot
            {...rest}
            variant="permanent"
            ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
        >
            <MDBox pt={1} pb={1} px={4} textAlign="center">
                <MDBox
                    display={{ xs: "block", xl: "none" }}
                    position="absolute"
                    top={0}
                    right={0}
                    p={1.625}
                    onClick={closeSidenav}
                    sx={{ cursor: "pointer" }}
                >
                    <MDTypography variant="h6" color="secondary">
                        <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                    </MDTypography>
                </MDBox>
            </MDBox>
            <List>{renderRoutes}</List>
        </SidenavRoot>
    );
};

// Typechecking props for the sidenav
Sidenav.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};
