import { RefObject, useEffect } from "react";

type ClickOutsideProps = {
    ref: RefObject<HTMLElement>;
    callback: (event: MouseEvent) => void;
    capture?: boolean;
};

export const useClickOutside = ({ ref, callback, capture }: ClickOutsideProps) => {
    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            const isContaining = ref.current?.contains(event.target as Node) ?? false;
            const shouldCall = Boolean(ref.current) && !isContaining;
            if (!shouldCall) return;
            callback(event);
        };
        window.addEventListener("click", handleClick, { capture });

        return () => window.removeEventListener("click", handleClick, { capture });
    }, [ref, callback, capture]);
};
