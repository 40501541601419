import Typography, { TypographyProps } from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

type TypographyColor =
    | "inherit"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark"
    | "text"
    | "white";

type FontWeight = false | "light" | "regular" | "medium" | "bold";

type TextTransform = "none" | "capitalize" | "uppercase" | "lowercase";
type VerticalAlign = "unset" | "baseline" | "sub" | "super" | "text-top" | "text-bottom" | "middle" | "top" | "bottom";

export interface OwnerState {
    color: TypographyColor;
    opacity: number;
    fontWeight: FontWeight;
    textTransform: TextTransform;
    verticalAlign: VerticalAlign;
    textGradient: boolean;
}

interface Props extends Omit<TypographyProps, "fontWeight"> {
    ownerState: OwnerState;
}

// @ts-ignore
export default styled(Typography)<Props>(({ theme, ownerState }) => {
    const { color, textTransform, verticalAlign, fontWeight, opacity, textGradient } = ownerState;

    const { gradients, transparent } = theme.palette;
    const { fontWeightLight, fontWeightRegular, fontWeightMedium, fontWeightBold } = theme.typography;
    const { linearGradient } = theme.functions;

    const fontWeights = {
        light: fontWeightLight,
        regular: fontWeightRegular,
        medium: fontWeightMedium,
        bold: fontWeightBold,
    };

    const gradientStyles = () => ({
        backgroundImage:
            color !== "inherit" && color !== "text" && color !== "white" && gradients[color]
                ? linearGradient(gradients[color].main, gradients[color].state)
                : linearGradient(gradients.dark.main, gradients.dark.state),
        display: "inline-block",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: transparent.main,
        position: "relative",
        zIndex: 1,
    });

    const colorValue = color === "inherit" || !theme.palette[color] ? "inherit" : theme.palette[color].main;
    const fontWeightValue = typeof fontWeight === "string" && fontWeights[fontWeight];

    return {
        opacity,
        textTransform,
        verticalAlign,
        textDecoration: "none",
        color: colorValue,
        fontWeight: fontWeightValue,
        ...(textGradient && gradientStyles()),
    };
});
