import { Api } from "api";
import { AdvertisementsAdapterImpl, GetListParams } from "./advertisements-list.types";

export class AdvertisementsListAdapter implements AdvertisementsAdapterImpl {
    public getList(data: GetListParams) {
        const filters: Api.Advertisement.Types.GetListParams["filters"] = {};
        const params: Api.Advertisement.Types.GetListParams = {};

        if (data.searchString?.length) {
            params.searchString = data.searchString;
        }

        if (data.category) {
            filters.category = data.category;
        }

        if (Object.keys(filters).length) {
            params.filters = filters;
        }

        if (data.pagination) {
            params.pagination = data.pagination;
        }

        return Api.apiRegistry.advertisementAPI.getList(params);
    }
}
