import styled from "styled-components";

export const Container = styled.div<{ $isOpened: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 800px;
    height: 38px;
    border-radius: ${({ $isOpened }) => ($isOpened ? "12px 12px 0 0" : "12px")};
`;

export const Outline = styled.div<{ $isOpened: boolean }>`
    display: flex;
    background-color: ${({ theme }) => theme.colors.content.primary};
    border-radius: ${({ $isOpened }) => ($isOpened ? "12px 12px 0 0" : "12px")};
    width: 100%;
    height: 100%;
`;

export const InputSection = styled.div<{ $isOpened: boolean }>`
    display: flex;
    border-radius: ${({ $isOpened }) => ($isOpened ? "12px 12px 0 0" : "12px")};
    background-color: #ffffff;
    flex-grow: 1;
    border: 2px solid ${({ theme }) => theme.colors.content.primary};
    border-bottom-color: ${({ $isOpened }) => ($isOpened ? "transparent" : "inherit")};
    height: 100%;
`;
