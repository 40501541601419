import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import { Store } from "app";
import { DictionaryDomain } from "app-domain";

interface Props {
    closeModal: VoidFunction;
}

export const CategoryContent = (props: Props) => {
    const store = Store.Hooks.useAdvertisementList();
    const dictionary = Store.Hooks.useDictionary();

    const navigate = useNavigate();

    const handleCategoryChange = (value?: string | number) => () => {
        store.searchController.handleSearchStringChange("");

        if (value) {
            const category = +value as DictionaryDomain.AdvertisementCategoryCode;
            store.searchController.setCategory(category);
            navigate(`/category/${DictionaryDomain.advertisementCategoryCode[category]}`);
        } else {
            store.searchController.setCategory(null);
            navigate("/category/all");
        }
        props.closeModal();
    };

    return (
        <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }} component="nav">
            <ListItemButton component="li" onClick={handleCategoryChange()}>
                <ListItemText>Все</ListItemText>
            </ListItemButton>
            {dictionary.advertisementCategories.map((category) => (
                <ListItemButton key={category.id} component="li" onClick={handleCategoryChange(category.id)}>
                    <ListItemText>{category.displayName}</ListItemText>
                    <ListItemIcon sx={{ minWidth: "unset" }}>
                        <KeyboardArrowRightIcon />
                    </ListItemIcon>
                </ListItemButton>
            ))}
        </List>
    );
};
