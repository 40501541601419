export const size = {
    contentWidth: "1200px",
    spacing: {
        xs: "4px",
        s: "8px",
        m: "12px",
        l: "16px",
        xl: "20px",
    },
    text: {
        xs: "12px",
        s: "14px",
        m: "16px",
        l: "18px",
    },
    radius: {
        s: "4px",
        m: "8px",
        l: "12px",
    },
};
