import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

interface Props {
    onRegistrationClick: VoidFunction;
}

export const SignInFooter = React.memo((props: Props) => {
    const { t } = useTranslation();

    return (
        <Grid container>
            <Grid item xs>
                <Link href="#" variant="body2">
                    {t`forgot-password`}
                </Link>
            </Grid>
            <Grid item>
                <Link style={{ cursor: "pointer" }} onClick={props.onRegistrationClick} variant="body2">
                    {t`sign-in-footer-text`}
                </Link>
            </Grid>
        </Grid>
    );
});
