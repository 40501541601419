import { makeAutoObservable, runInAction } from "mobx";
import Cookie from "js-cookie";
import { UserDomain } from "app-domain";
import { UserAdapterImpl } from "./user.types";

export class User {
    public isLoading = false;
    public userId: NullableString = Cookie.get("uid") || null;
    public profile: UserDomain.Profile | null = null;
    public data: UserDomain.User | null = null;

    constructor(private service: UserAdapterImpl) {
        makeAutoObservable(this);
    }

    public async init(id: string) {
        runInAction(() => {
            this.isLoading = true;
        });

        const user = await this.service.getUser(id);
        const profile = await this.service.getProfile(id);

        runInAction(() => {
            this.profile = profile;
            this.data = user;
            this.isLoading = false;
        });
    }

    public async deleteUser() {
        if (!this.userId) return;

        try {
            await this.service.deleteUser(this.userId);

            this.reset();

            return true;
        } catch {
            return false;
        }
    }

    public reset() {
        this.userId = null;
        this.profile = null;
        this.data = null;
    }
}
