import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { appEnv } from "shared";
import { SwiperHover } from "components";
import * as Markup from "./image-preview.styles";

interface Props {
    isLoading: boolean;
    href: string;
    images?: string[];
}

export const ImagePreview = React.memo((props: Props) => {
    if (props.isLoading) return <Skeleton variant="rounded" width="100%" height="180px" />;

    return (
        <Markup.Link target="_blank" href={props.href} rel="noreferrer">
            <Markup.ImageWrapper>
                <SwiperHover>
                    {props.images?.map((url) => (
                        <Markup.ImageContainer key={url}>
                            <Markup.AdaptiveImage loading="lazy" alt="advertisement" src={`${appEnv.cdn}/${url}`} />
                        </Markup.ImageContainer>
                    ))}
                </SwiperHover>
            </Markup.ImageWrapper>
        </Markup.Link>
    );
});
