import { makeAutoObservable, runInAction } from "mobx";
import { DictionaryDomain } from "app-domain";
import { Currencies, DictionaryAdapterImpl } from "./dictionary.types";

export class Dictionary {
    public advertisementCategories: DictionaryDomain.AdvertisementCategory[] = [];
    public currencies: Currencies = new Map();

    constructor(private service: DictionaryAdapterImpl) {
        makeAutoObservable(this);
        this.loadDictionaries();
    }

    public get currenciesList() {
        return Array.from(this.currencies);
    }

    public async loadDictionaries() {
        const currencies = await this.service.getCurrencies();
        const advertisementCategories = await this.service.getAdvertisementCategories();

        runInAction(() => {
            this.currencies = currencies;
            this.advertisementCategories = advertisementCategories;
        });
    }
}
