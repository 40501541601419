import React from "react";

export const useObserveVisibility = (ref: React.RefObject<HTMLElement>) => {
    const [isVisible, setIsVisible] = React.useState(false);
    const observerRef = React.useRef<IntersectionObserver>();

    React.useLayoutEffect(() => {
        if (!ref.current || observerRef.current) return;
        const handler = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio !== 0) {
                    setIsVisible(true);
                }
            });
        };

        observerRef.current = new IntersectionObserver(handler, {
            root: null,
            rootMargin: "0px",
            threshold: [0.0, 0.75],
        });

        observerRef.current.observe(ref.current);
    }, [ref]);

    return isVisible;
};
