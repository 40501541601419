import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

export const cardMediaStyles: SxProps<Theme> = {
    height: "180px",
    width: "100%",
    margin: 0,
    boxShadow: ({ boxShadows: { md } }) => md,
    objectFit: "cover",
    objectPosition: "center",
};
