import { BaseAPI } from "api/utils";
import { DictionaryDomain } from "app-domain";
import { CurrencyDictionary } from "api/types";

export class DictionaryAPI extends BaseAPI {
    public getCurrencies(): Promise<CurrencyDictionary[]> {
        const url = this.buildUrl("/currencies");

        return this.request({ url });
    }

    public getAdvertisementCategories(): Promise<DictionaryDomain.AdvertisementCategory[]> {
        const url = this.buildUrl("/advertisement-categories");

        return this.request({ url });
    }

    protected buildUrl(endpoint: string = ""): string {
        return super.buildUrl(`/trade/dictionaries${endpoint}`);
    }
}
