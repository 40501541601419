import Cookie from "js-cookie";
import { BaseAPI } from "api/utils";
import { PaidBlockDomain } from "app-domain";

export class PaidBlockAPI extends BaseAPI {
    public readonly getPaidBlock = (type: string): Promise<PaidBlockDomain.PaidBlock[]> => {
        const url = this.buildUrl(`?type=${type}`);

        return this.request({ url, token: Cookie.get("access-token") });
    };

    protected buildUrl(endpoint: string = ""): string {
        return super.buildUrl(`/trade/paidblock${endpoint}`);
    }
}
