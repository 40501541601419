import { reaction } from "mobx";
import { Store } from "app/index";

export class UserAuth {
    constructor(private appState: Store.AppState) {
        this.subscribe();

        if (this.appState.auth.isLogin) {
            this.appState.advertisements.searchController.onModeChange(
                this.appState.advertisements.searchController.modes.Remote
            );
            if (this.appState.auth.userId) {
                this.appState.user.init(this.appState.auth.userId);
            }
        } else {
            this.appState.advertisements.searchController.onModeChange(
                this.appState.advertisements.searchController.modes.Local
            );
        }
    }

    private subscribe() {
        reaction(() => this.appState.auth.isLogin, this.onLoginChange);
    }

    private onLoginChange = (isLogin: boolean) => {
        const userStore = this.appState.user;
        const authStore = this.appState.auth;

        if (!authStore.isLoading) {
            if (isLogin) {
                this.appState.advertisements.searchController.onModeChange(
                    this.appState.advertisements.searchController.modes.Remote
                );
            } else {
                this.appState.advertisements.searchController.onModeChange(
                    this.appState.advertisements.searchController.modes.Local
                );
            }
        }

        if (!isLogin && !authStore.userId && !authStore.isLoading && userStore.profile) {
            userStore.reset();
        }

        if (!isLogin || !authStore.userId) return;

        if (userStore.isLoading) return;

        userStore.init(authStore.userId);
    };
}
