import Cookie from "js-cookie";
import { BaseAPI } from "api/utils";
import { GetProfileResponse, GetUserResponse } from "./user-api.types";

export class UserAPI extends BaseAPI {
    public getProfile(id: string | number): Promise<GetProfileResponse> {
        const url = this.buildUrl(`/profile?userId=${id}`);

        return this.request({ url, token: Cookie.get("access-token") });
    }

    public getUser(id: string): Promise<GetUserResponse> {
        const url = this.buildUrl(`/user/${id}`);

        return this.request({ url, token: Cookie.get("access-token") });
    }

    public deleteUser(id: string): Promise<void> {
        const url = this.buildUrl(`/user/${id}`);

        return this.request({ url, method: "DELETE", token: Cookie.get("access-token") });
    }

    protected buildUrl(endpoint: string = ""): string {
        return super.buildUrl(`/trade/auth${endpoint}`);
    }
}
