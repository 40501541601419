export const boxStyle = {
    position: "fixed" as "absolute",
    top: "41px",
    width: "100%",
    bgcolor: "background.paper",
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    color: "info.main",
    borderBottom: "2px solid",
    borderRight: "2px solid",
    borderLeft: "2px solid",
    overflow: "hidden",
};
