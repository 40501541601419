// Material Dashboard 2 React base styles
import { palette } from "assets/theme/base/palette";
import typography from "assets/theme/base/typography";

const { grey } = palette;
const { size } = typography;

const breadcrumbs = {
    styleOverrides: {
        li: {
            lineHeight: 0,
            fontSize: 16,
        },

        separator: {
            fontSize: size.sm,
            color: grey[600],
        },
    },
};

export default breadcrumbs;
