import Cookie from "js-cookie";
import { BaseAPI, ValidatedResult } from "api/utils";
import { LoginForm, RegistrationForm, VerifyForm } from "api/types";
import { LoginResponse, RegisterResponse, VerifyResponse } from "./auth-api.types";

export class AuthApi extends BaseAPI {
    public async login(form: LoginForm): Promise<LoginResponse> {
        const url = this.buildUrl("/login");

        const result = await this.request({
            url,
            method: "POST",
            body: JSON.stringify(form),
        }).catch((error) => error);

        return new ValidatedResult(result);
    }

    public async register(form: RegistrationForm): Promise<RegisterResponse> {
        const url = this.buildUrl("/register");

        const result = await this.request({
            url,
            method: "POST",
            body: JSON.stringify(form),
        });

        return new ValidatedResult(result);
    }

    public async verify(form: VerifyForm): Promise<VerifyResponse> {
        const url = this.buildUrl("/verify");

        const result = await this.request({
            url,
            method: "POST",
            as: "raw",
            token: Cookie.get("access-token"),
            body: JSON.stringify(form),
        });

        return new ValidatedResult(result);
    }

    public async resendVerification(): Promise<ValidatedResult<{}>> {
        const url = this.buildUrl("/resend-verification-mail");

        const result = await this.request({
            url,
            method: "POST",
            token: Cookie.get("access-token"),
        });

        return new ValidatedResult(result);
    }

    protected buildUrl(endpoint: string = ""): string {
        return super.buildUrl(`/trade/auth${endpoint}`);
    }
}
