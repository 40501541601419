import { palette } from "assets/theme/base/palette";

// Material Dashboard 2 React Helper Functions
import { pxToRem } from "assets/theme/functions";

type BorderRadius = "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "section";

export interface Borders {
    borderColor: string;
    borderWidth: {
        0: number;
        1: string;
        2: string;
        3: string;
        4: string;
        5: string;
    };
    borderRadius: Record<BorderRadius, string>;
}

const borders = {
    borderColor: palette.grey[300],

    borderWidth: {
        0: 0,
        1: pxToRem(1),
        2: pxToRem(2),
        3: pxToRem(3),
        4: pxToRem(4),
        5: pxToRem(5),
    },

    borderRadius: {
        xs: pxToRem(1.6),
        sm: pxToRem(2),
        md: pxToRem(6),
        lg: pxToRem(8),
        xl: pxToRem(12),
        xxl: pxToRem(16),
        section: pxToRem(160),
    },
};

export default borders;
