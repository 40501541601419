import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Store } from "app";
const emailValidatorRegexp = new RegExp(/.+@.+\..+/i);

export const useForm = () => {
    const authStore = Store.Hooks.useAuthStore();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [firstName, setFirstName] = React.useState("");
    const [secondName, setSecondName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");

    const [firstNameError, setFirstNameError] = React.useState("");
    const [secondNameError, setSecondNameError] = React.useState("");
    const [loginError, setLoginError] = React.useState("");
    const [passwordError, setPasswordError] = React.useState("");

    const handleSubmit = React.useCallback(() => {
        const form = {
            firstName,
            secondName,
            email,
            password,
        };

        let hasError = !firstName.trim() || !secondName.trim() || !email.trim() || !password.trim();

        if (!email.trim()) setLoginError("Обязательное поле");
        if (!password.trim()) setPasswordError("Обязательное поле");
        if (!firstName.trim()) setFirstNameError("Обязательное поле");
        if (!secondName.trim()) setSecondNameError("Обязательное поле");

        if (!emailValidatorRegexp.test(email)) {
            hasError = true;
            setLoginError("Не валидное значение");
        }

        if (hasError) return;

        authStore.registration(form).then(() => {
            if (!authStore.isLogin) return;

            const query = new URLSearchParams();

            query.set("auth", "verify");

            navigate(`${pathname}?${query}`);
        });
    }, [pathname, authStore, navigate, firstName, secondName, email, password]);

    const handleFirstNameChange: ChangeEventHandler = (event) => {
        setFirstName(event.target.value);
    };
    const handleSecondNameChange: ChangeEventHandler = (event) => {
        setSecondName(event.target.value);
    };
    const handleLoginChange: ChangeEventHandler = (event) => {
        setEmail(event.target.value);
    };
    const handlePasswordChange: ChangeEventHandler = (event) => {
        setPassword(event.target.value);
    };

    return {
        errorMessage: authStore.registerErrorMessage,
        firstName,
        secondName,
        email,
        password,
        firstNameError,
        secondNameError,
        loginError,
        passwordError,
        isLoading: authStore.isLoading,
        handleSubmit,
        handleFirstNameChange,
        handleSecondNameChange,
        handleLoginChange,
        handlePasswordChange,
    };
};
