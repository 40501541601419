import { Api } from "api";
import { Auth, AuthAdapter } from "./auth";
import { User } from "./user";
import { AdvertisementsList, AdvertisementsListAdapter } from "./advertisements-list";
import { WishList } from "./wish-list";
import { BrokerManager } from "./broker-manager";
import { Dictionary, DictionaryAdapter } from "./dictionary";
import { UserAdvertisements } from "./user-advertisements";
import { AdvertisementsViewHistory } from "./advertisements-view-history";

export class AppState {
    public readonly advertisementsViewHistory = new AdvertisementsViewHistory(Api.apiRegistry.advertisementAPI);
    public readonly wishList = new WishList(Api.apiRegistry.wishListAPI);
    public readonly dictionary = new Dictionary(new DictionaryAdapter());
    public readonly auth = new Auth(new AuthAdapter());
    public readonly user = new User(Api.apiRegistry.userAPI);
    public readonly userAdvertisements = new UserAdvertisements(Api.apiRegistry.advertisementAPI);
    public readonly advertisements = new AdvertisementsList(
        new AdvertisementsListAdapter(),
        Api.apiRegistry.searchHistoryAPI
    );
    private readonly brokerManager: BrokerManager;
    constructor() {
        this.brokerManager = new BrokerManager(this);
    }
}

export const appState = new AppState();

export const { advertisementsViewHistory, wishList, dictionary, auth, user, userAdvertisements, advertisements } =
    appState;
