import * as React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import { Store } from "app";
import { Hooks } from "shared";
import { Header } from "./header";
import { SignInForm } from "./sign-in-form";
import { SignUpForm } from "./sign-up-form";
import { VerifyForm } from "./verify-form";
import { SignInFooter } from "./sign-in-footer";
import { SignUpFooter } from "./sign-up-footer";
import * as Markup from "./auth.styles";

const useHeaderMessage = () => {
    const { t } = useTranslation();
    const authPath = Hooks.useAuthPath();

    if (authPath.activePath === "sign-in") return t`sign-in`;
    if (authPath.activePath === "sign-up") return t`sign-up`;
    if (authPath.activePath === "verify") return t`verify`;

    return "";
};

export const Auth = observer(() => {
    const userStore = Store.Hooks.useUserStore();
    const authStore = Store.Hooks.useAuthStore();
    const authPath = Hooks.useAuthPath();
    const headerMessage = useHeaderMessage();

    React.useEffect(() => {
        if (authStore.isLogin) {
            if (authPath.activePath === "sign-in" || authPath.activePath === "sign-up") {
                authPath.handleClose();
            }
            if (authPath.activePath === "verify" && userStore.data?.isVerified) {
                authPath.handleClose();
            }
        }
    }, [userStore, authStore, authPath]);

    const handleRegistrationClick = () => {
        if (authStore.isLoading) {
            return;
        }
        authPath.handleOpenSignUp();
    };

    const handleSignInClick = () => {
        if (authStore.isLoading) {
            return;
        }
        authPath.handleOpenSignIn();
    };

    if (authStore.isLogin && userStore.data?.isVerified) return null;

    return (
        <Modal
            open={!!authPath.activePath}
            onClose={authPath.handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description">
            <Markup.Container>
                <Header>{headerMessage}</Header>
                <Markup.Form>
                    {authPath.activePath === "verify" && <VerifyForm />}
                    {authPath.activePath === "sign-in" && <SignInForm />}
                    {authPath.activePath === "sign-up" && <SignUpForm />}
                </Markup.Form>
                {authPath.activePath === "sign-in" && <SignInFooter onRegistrationClick={handleRegistrationClick} />}
                {authPath.activePath === "sign-up" && <SignUpFooter onSignInClick={handleSignInClick} />}
            </Markup.Container>
        </Modal>
    );
});
