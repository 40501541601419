import { Api } from "api";
import { Currencies, DictionaryAdapterImpl } from "./dictionary.types";

export class DictionaryAdapter implements DictionaryAdapterImpl {
    private dictionaryAPI = Api.apiRegistry.dictionaryAPI;

    public async getCurrencies() {
        const result = await this.dictionaryAPI.getCurrencies();

        return result.reduce((acc: Currencies, dictionary) => {
            acc.set(dictionary.code, dictionary);
            return acc;
        }, new Map());
    }

    public getAdvertisementCategories() {
        return this.dictionaryAPI.getAdvertisementCategories();
    }
}
