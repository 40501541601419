import styled from "styled-components";

export const Link = styled.a`
    text-decoration: none;
`;

export const InfoHead = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Title = styled.span`
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 20px;
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    word-break: break-word;
    color: #242629;
    outline-color: currentColor;
    cursor: pointer;

    &:hover {
        color: #ff6163;
    }
`;
