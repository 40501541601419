import styled from "styled-components";
export const Container = styled.div`
    padding: 8px;
    border-radius: 8px;
    background-color: #ffffff;
`;

export const List = styled.div`
    display: flex;
    gap: 8px;
    overflow-x: auto;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const Title = styled.h1`
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 20px;
    padding: 0 24px;
    text-align: center;
`;
