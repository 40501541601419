import React from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { Store } from "app";

export const InputContent = observer(() => {
    const { searchController, submit } = Store.Hooks.useAdvertisementList();
    const navigate = useNavigate();
    const location = useLocation();

    const handleRemove = (text: string) => () => {
        return searchController.removeRecord(text);
    };

    const handleRecordSelect = (text: string) => () => {
        searchController.onRecordSelect(text);
        submit();
        searchController.setModalContentType(null);
        if (!location.pathname.includes("category")) {
            navigate("/category/all");
        }
    };

    return (
        <>
            {!!searchController.variants.length ? (
                <>
                    <List>
                        {searchController.variants.map((text, index) => (
                            <ListItem key={index} disablePadding>
                                <ListItemButton
                                    sx={{ py: 0.5 }}
                                    role={undefined}
                                    dense
                                    onClick={handleRecordSelect(text)}
                                >
                                    <ListItemText primary={text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </>
            ) : (
                <List>
                    {searchController.searchHistory.map((text, index) => (
                        <ListItem
                            key={index}
                            disablePadding
                            secondaryAction={
                                <IconButton
                                    edge="start"
                                    onClick={handleRemove(text)}
                                    size="small"
                                    aria-label="delete"
                                    sx={{ mr: 1 }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            }
                        >
                            <ListItemButton sx={{ py: 0.5 }} role={undefined} dense onClick={handleRecordSelect(text)}>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            )}
        </>
    );
});
