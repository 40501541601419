import React from "react";
import { useTranslation } from "react-i18next";
import * as Markup from "./empty.styles";

export const EmptyDrawer = React.memo(() => {
    const { t } = useTranslation();

    return (
        <Markup.Container>
            <Markup.Info>
                <Markup.Image
                    src="//www.gstatic.com/mobilesdk/180824_mobilesdk/alertsDrawerEmptyState@2x.png"
                    alt="Empty Drawer"
                    loading="lazy"
                />
                <Markup.Title>{t`dont-have-alerts`}</Markup.Title>
                <Markup.Description>{t`empty-notifications-message`}</Markup.Description>
            </Markup.Info>
            <Markup.Button variant="contained">{t`see-alert-history`}</Markup.Button>
        </Markup.Container>
    );
});
