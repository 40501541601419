import React from "react";

export const useOnline = () => {
    const [value, setValue] = React.useState(window.navigator.onLine);

    const handleOffLine = React.useCallback(() => {
        setValue(false);
    }, []);

    const handleOnLine = React.useCallback(() => {
        setValue(true);
    }, []);

    React.useEffect(() => {
        window.addEventListener("offline", handleOffLine);
        window.addEventListener("online", handleOnLine);

        return () => {
            window.removeEventListener("offline", handleOffLine);
            window.removeEventListener("online", handleOnLine);
        };
    }, [handleOnLine, handleOffLine]);

    return value;
};
