import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { AdvertisementDomain } from "app-domain";
import { Store } from "app";
import { MediaCard, InfoCard } from "./components";
import * as Markup from "./adv-card.styles";

interface Props {
    isLoading?: boolean;
    id?: string;
    image?: string;
    title?: string;
    route?: string;
    status?: AdvertisementDomain.Status;
    price?: AdvertisementDomain.Price;
    description?: NullableString;
    style?: React.CSSProperties;
}
export const AdvCard = observer((props: Props) => {
    const dictionary = Store.Hooks.useDictionary();
    const wishListStore = Store.Hooks.useWishList();

    const isInWishList = !!props.id && wishListStore.availableIds.has(props.id);

    const handleNavigate = (event: React.DragEvent) => {
        event.preventDefault();
    };

    const handleWishListClick = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (!props.id) return;

        if (isInWishList) {
            wishListStore.removeFormList(props.id);
        } else {
            wishListStore.addToList(props.id);
        }
    };

    return (
        <Link to={props.route ?? ""} target="_blank" onDragStart={handleNavigate} style={{ textDecoration: "none" }}>
            <Markup.Container style={props.style} $isLoading={props.isLoading}>
                <MediaCard
                    isInWishList={isInWishList}
                    isLoading={props.isLoading}
                    title={props.title}
                    image={props.image}
                    onWishlistClick={handleWishListClick}
                />
                <InfoCard
                    price={props.price}
                    currencies={dictionary.currencies}
                    title={props.title}
                    description={props.description}
                    isLoading={props.isLoading}
                />
            </Markup.Container>
        </Link>
    );
});
