import { ResidentialRealEstate } from "../enums";

export const residentialRealEstateList = [
    { value: ResidentialRealEstate.NewBuildingFlat, label: "Новостройка" },
    { value: ResidentialRealEstate.OldBuildingFlat, label: "Вторичка" },
    { value: ResidentialRealEstate.House, label: "Дом" },
    { value: ResidentialRealEstate.PartHouse, label: "Часть дома" },
    { value: ResidentialRealEstate.Townhouse, label: "Таунхаус" },
    { value: ResidentialRealEstate.Land, label: "Земля" },
    { value: ResidentialRealEstate.Garage, label: "Гараж" },
];

export const residentialRealEstateMap = {
    [ResidentialRealEstate.NewBuildingFlat]: "Новостройка",
    [ResidentialRealEstate.OldBuildingFlat]: "Вторичка",
    [ResidentialRealEstate.House]: "Дом",
    [ResidentialRealEstate.PartHouse]: "Часть дома",
    [ResidentialRealEstate.Townhouse]: "Таунхаус",
    [ResidentialRealEstate.Land]: "Земля",
    [ResidentialRealEstate.Garage]: "Гараж",
};
