import styled from "styled-components/macro";
import MuiButton from "@mui/material/Button";
import boxShadows from "assets/theme/base/boxShadows";

export const Button = styled(MuiButton)<{ front: "right" | "left" }>`
    && {
        position: absolute;
        top: calc(50% - 16px);
        left: ${({ front }) => (front === "right" ? `calc(100% - 40px)` : "8px")};
        border-radius: 50%;
        transition: 0.2s;
        min-width: 38px;
        min-height: 38px;
        width: 38px;
        height: 38px;
        padding: 0;
        background-color: #ffffff;

        &:hover,
        &:focus {
            background-color: #e4e4e4 !important;
            box-shadow: ${boxShadows.md};
        }

        svg {
            fill: ${({ theme }) => theme.colors.content.primary};
        }
    }
`;
