import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

export const cardMedia: SxProps<Theme> = {
    height: "180px",
    width: "100%",
    margin: 0,
    boxShadow: ({ boxShadows: { md } }) => md,
    objectFit: "cover",
    objectPosition: "center",
};

export const descriptionStyles = {
    "overflow": "hidden",
    "display": "-webkit-box",
    "webkitLineClamp": 2,
    "-webkit-line-clamp": 2,
    "lineClamp": 2,
    "height": 42,
    "wordWrap": "break-word",
    "textOverflow": "ellipsis",
    "boxOrient": "vertical",
};
