import Cookie from "js-cookie";
import { BaseAPI } from "api/utils";
import { RemoveRecordParams } from "./search-history-api.types";

export class SearchHistoryAPI extends BaseAPI {
    public getHistory(): Promise<string[]> {
        const url = this.buildUrl("/history");

        return this.request({ url, token: Cookie.get("access-token") });
    }

    public getVariants(searchString: string, signal: AbortSignal): Promise<string[]> {
        const url = this.buildUrl("/variants");

        return this.request({
            url,
            token: Cookie.get("access-token"),
            body: JSON.stringify({ searchString }),
            signal,
            method: "POST",
        });
    }

    public removeRecord(params: RemoveRecordParams): Promise<string[]> {
        const url = this.buildUrl("/history");

        return this.request({
            url,
            method: "DELETE",
            body: JSON.stringify(params),
            token: Cookie.get("access-token"),
        });
    }

    protected buildUrl(endpoint: string = ""): string {
        return super.buildUrl(`/trade/advertisements/search${endpoint}`);
    }
}
