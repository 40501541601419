import React from "react";
import { useTranslation } from "react-i18next";
import WifiOffOutlined from "@mui/icons-material/WifiOffOutlined";
import MDTypography from "components/MDTypography";
import * as Markup from "./offline-styles";

export const Offline = React.memo(() => {
    const { t } = useTranslation();

    return (
        <Markup.Container>
            <WifiOffOutlined fontSize="large" color="info" />
            {/*// @ts-ignore*/}
            <MDTypography variant="h4">{t`offline-title`}</MDTypography>
            {/*// @ts-ignore*/}
            <MDTypography variant="body2">{t`offline-description`}</MDTypography>
        </Markup.Container>
    );
});
