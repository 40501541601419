import styled, { css } from "styled-components";
import Card from "@mui/material/Card";

export const Container = styled(Card)<{ $isLoading?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 250px;
    cursor: pointer;
    user-select: none;

    ${({ $isLoading }) =>
        $isLoading
            ? css`
                  box-shadow: none !important;
              `
            : css`
                  &:not(&:hover) {
                      box-shadow: none !important;
                  }
              `}
`;
