import { AdvertisementCategoryCode } from "../enums";

export const advertisementCategoryCode: Record<AdvertisementCategoryCode, string> = {
    [AdvertisementCategoryCode.Transport]: "transport",
    [AdvertisementCategoryCode.Realty]: "realty",
    [AdvertisementCategoryCode.Appliances]: "appliances",
    [AdvertisementCategoryCode.Electronics]: "electronics",
    [AdvertisementCategoryCode.Food]: "food",
    [AdvertisementCategoryCode.SportHobby]: "sport-hobby",
    [AdvertisementCategoryCode.HomeGarden]: "home-garden",
    [AdvertisementCategoryCode.Animals]: "animals",
    [AdvertisementCategoryCode.EquipmentMaterials]: "equipment-materials",
    [AdvertisementCategoryCode.FashionStyle]: "fashion-style",
};

export const advertisementCategory: Record<string, AdvertisementCategoryCode> = {
    "transport": AdvertisementCategoryCode.Transport,
    "realty": AdvertisementCategoryCode.Realty,
    "appliances": AdvertisementCategoryCode.Appliances,
    "electronics": AdvertisementCategoryCode.Electronics,
    "animals": AdvertisementCategoryCode.Animals,
    "food": AdvertisementCategoryCode.Food,
    "sport-hobby": AdvertisementCategoryCode.SportHobby,
    "home-garden": AdvertisementCategoryCode.HomeGarden,
    "equipment-materials": AdvertisementCategoryCode.EquipmentMaterials,
    "fashion-style": AdvertisementCategoryCode.FashionStyle,
};
