import { SportType } from "../enums";

export const sportTypeMap: Record<SportType, string> = {
    [SportType.Bicycle]: "Велосипеды и запчасти",
};

export const sportTypeList = [
    {
        value: SportType.Bicycle,
        label: sportTypeMap[SportType.Bicycle],
    },
];
