/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React base styles
import { palette } from "assets/theme/base/palette";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

// Material Dashboard 2 React helper functions
import { pxToRem } from "assets/theme/functions";
import { linearGradient } from "assets/theme/functions";

const { transparent, gradients } = palette;
const { borderRadius } = borders;
const { colored } = boxShadows;

const stepper = {
    styleOverrides: {
        root: {
            "background": linearGradient(gradients.info.main, gradients.info.state),
            "padding": `${pxToRem(24)} 0 ${pxToRem(16)}`,
            "borderRadius": borderRadius.lg,
            "boxShadow": colored.info,

            "&.MuiPaper-root": {
                backgroundColor: transparent.main,
            },
        },
    },
};

export default stepper;
