import { makeAutoObservable, reaction, runInAction } from "mobx";
import { AdvertisementDomain } from "app-domain";
import { SearchController, SearchControllerServiceImpl } from "./search-controller";
import { AdvertisementsAdapterImpl } from "./advertisements-list.types";

const emptyList: null[] = new Array(30).fill(null);

export class AdvertisementsList {
    public isListLoading = false;
    public list: (AdvertisementDomain.Advertisement | null)[] = [];
    public searchController: SearchController;
    public page = 1;
    public totalCount = 0;
    public isInitialized = false;
    private readonly pageSize = 30;

    constructor(private service: AdvertisementsAdapterImpl, searchControllerService: SearchControllerServiceImpl) {
        makeAutoObservable(this);
        this.searchController = new SearchController(searchControllerService);
        this.subscribe();
    }

    public get hasMore() {
        return this.totalCount !== null && this.totalCount > this.list.length;
    }

    public async loadList() {
        if (this.isListLoading || this.isInitialized) return;

        this.isListLoading = true;

        const searchParams = this.searchController.build();
        const pagination = { page: this.page, pageSize: this.pageSize };

        const data = await this.service.getList({ ...searchParams, pagination });

        runInAction(() => {
            this.list = data.items;
            this.totalCount = data.total;
            this.isListLoading = false;
            this.isInitialized = true;
        });
    }

    public readonly submit = () => {
        this.list = emptyList;
        this.isInitialized = false;
        this.loadList();
    };

    public readonly loadMore = async () => {
        if (this.isListLoading) return;
        this.isListLoading = true;

        ++this.page;

        const searchParams = this.searchController.build();
        const pagination = { page: this.page, pageSize: this.pageSize };

        try {
            const data = await this.service.getList({ ...searchParams, pagination });

            runInAction(() => {
                this.list.push(...data.items);
                this.totalCount = data.total;
                this.isListLoading = false;
            });
        } catch {
            runInAction(() => {
                this.isListLoading = false;
            });
        }
    };

    private subscribe() {
        reaction(() => this.searchController.category, this.submit);
    }
}
