// Material Dashboard 2 React Base Styles
// import { palette } from "assets/theme/base/palette";

// const { transparent } = palette;

const iconButton = {
    styleOverrides: {
        root: {
            "&:hover": {
                // backgroundColor: transparent.main,
            },
        },
    },
};

export default iconButton;
