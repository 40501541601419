import React from "react";

interface SearchControlContextType {
    inputRef?: React.MutableRefObject<HTMLInputElement | null>;
    modalRef?: React.MutableRefObject<HTMLDivElement | null>;
    containerRef?: React.MutableRefObject<HTMLDivElement | null>;
}

export const SearchControlContext = React.createContext<SearchControlContextType>({});

export const useSearchControlContext = () => React.useContext(SearchControlContext);
