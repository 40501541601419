import { forwardRef } from "react";
import { TextFieldProps } from "@mui/material/TextField";
import InputRoot, { OwnerState } from "components/input/input-root";

export const Input = forwardRef<HTMLInputElement, OwnerState & TextFieldProps>(
    ({ error = false, success = false, disabled = false, ...rest }, ref) => (
        <InputRoot {...rest} ref={ref} ownerState={{ error, success, disabled }} />
    )
);

export default Input;
