import React from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "app";
import { Hooks } from "shared";

export const usePrivateLinkClick = (path: string, callback?: VoidFunction) => {
    const navigate = useNavigate();
    const authPath = Hooks.useAuthPath();

    return React.useCallback(() => {
        callback?.();

        if (Store.auth.isLogin) {
            if (Store.user.data && !Store.user.data.isVerified) {
                return authPath.handleOpenVerify();
            }

            return navigate(path);
        }

        authPath.handleOpenSignIn(path);
    }, [navigate, authPath, path, callback]);
};
