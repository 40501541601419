import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "./use-query";

export const useAuthPath = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleOpenSignIn = (redirect?: string) => {
        query.set("auth", "sign-in");
        if (redirect) {
            query.set("redirect", redirect);
        }
        navigate(`${pathname}?${query}`);
    };

    const handleOpenSignUp = () => {
        query.set("auth", "sign-up");
        navigate(`${pathname}?${query}`);
    };

    const handleOpenVerify = () => {
        query.set("auth", "verify");
        navigate(`${pathname}?${query}`);
    };

    const handleClose = React.useCallback(() => {
        query.delete("auth");
        query.delete("redirect");

        navigate(`${pathname}?${query}`);
    }, [query, navigate, pathname]);

    return {
        activePath: query.get("auth"),
        handleClose,
        handleOpenSignIn,
        handleOpenSignUp,
        handleOpenVerify,
    };
};
