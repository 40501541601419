import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Color, ColorBase } from "./MDBoxRoot.types";

const validGradients: ColorBase[] = ["primary", "secondary", "info", "success", "warning", "error", "dark", "light"];

const validColors: Color[] = [
    "transparent",
    "white",
    "black",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "text",
    "grey-100",
    "grey-200",
    "grey-300",
    "grey-400",
    "grey-500",
    "grey-600",
    "grey-700",
    "grey-800",
    "grey-900",
];

const validBorderRadius = ["xs", "sm", "md", "lg", "xl", "xxl", "section"];
const validBoxShadows = ["xs", "sm", "md", "lg", "xl", "xxl", "inset"];

type ColoredShadow = "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "none";

export interface OwnerState {
    variant: "contained" | "gradient";
    bgColor: Color;
    color: ColorBase;
    opacity: number;
    borderRadius: string;
    shadow: string;
    coloredShadow: ColoredShadow;
}

export interface Props extends BoxProps {
    ownerState: OwnerState;
}

export default styled(Box)<Props>(({ theme, ownerState }) => {
    const { palette, functions, borders, boxShadows } = theme;
    const { variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow } = ownerState;

    const { grey, white } = palette;
    const { linearGradient } = functions;
    const { borderRadius: radius } = borders;
    const { colored } = boxShadows;

    const greyColors = {
        "grey-100": grey[100],
        "grey-200": grey[200],
        "grey-300": grey[300],
        "grey-400": grey[400],
        "grey-500": grey[500],
        "grey-600": grey[600],
        "grey-700": grey[700],
        "grey-800": grey[800],
        "grey-900": grey[900],
    };

    // background value
    let backgroundValue: string = bgColor;

    if (variant === "gradient") {
        backgroundValue = validGradients.find((el) => el === bgColor)
            ? linearGradient(
                  palette.gradients[bgColor as ColorBase].main,
                  palette.gradients[bgColor as ColorBase].state
              )
            : white.main;
    } else if (validColors.find((el) => el === bgColor)) {
        // @ts-ignore
        backgroundValue = !!palette[bgColor]
            ? // @ts-ignore
              palette[bgColor as Color].main
            : // @ts-ignore
              greyColors[bgColor as Color];
    } else {
        backgroundValue = bgColor;
    }

    // color value
    let colorValue = color;

    if (validColors.find((el) => el === color)) {
        // @ts-ignore
        colorValue = palette[color] ? palette[color].main : greyColors[color as keyof typeof greyColors];
    }

    // borderRadius value
    let borderRadiusValue = borderRadius;

    if (validBorderRadius.find((el) => el === borderRadius)) {
        // @ts-ignore
        borderRadiusValue = radius[borderRadius];
    }

    // boxShadow value
    let boxShadowValue = "none";

    if (validBoxShadows.find((el) => el === shadow)) {
        // @ts-ignore
        boxShadowValue = boxShadows[shadow];
    } else if (coloredShadow) {
        // @ts-ignore
        boxShadowValue = colored[coloredShadow] ? colored[coloredShadow] : "none";
    }

    return {
        opacity,
        background: backgroundValue,
        color: colorValue,
        borderRadius: borderRadiusValue,
        boxShadow: boxShadowValue,
    };
});
