import { ReactComponent as LanguageHy } from "assets/icons/flag-armenia.svg";
import { ReactComponent as LanguageRu } from "assets/icons/flag-russia.svg";
import { ReactComponent as LanguageEn } from "assets/icons/flag-britain.svg";
import * as Markup from "./language-select.styles";

export const menuItems: Record<string, { icon: JSX.Element; label: JSX.Element }> = {
    hy: {
        icon: <LanguageHy style={{ width: 30, height: 20 }} />,
        label: <Markup.Text>Հայերեն</Markup.Text>,
    },
    ru: {
        icon: <LanguageRu style={{ width: 30, height: 20 }} />,
        label: <Markup.Text>Русский</Markup.Text>,
    },
    en: { icon: <LanguageEn style={{ width: 30, height: 20 }} />, label: <Markup.Text>English</Markup.Text> },
};
