import { CommerceRealEstate } from "../enums";

export const commerceRealEstateList = [
    {
        value: CommerceRealEstate.Office,
        label: "Офис",
    },
    {
        value: CommerceRealEstate.TradeArea,
        label: "Торговая площадь",
    },
    {
        value: CommerceRealEstate.Stock,
        label: "Склад",
    },
    {
        value: CommerceRealEstate.FreeSpace,
        label: "Помещение свободного назначения",
    },
    {
        value: CommerceRealEstate.Production,
        label: "Производство",
    },
    {
        value: CommerceRealEstate.CarService,
        label: "Автосервис",
    },
    {
        value: CommerceRealEstate.Building,
        label: "Здание",
    },
];

export const commerceRealEstateMap = {
    [CommerceRealEstate.Office]: "Офис",
    [CommerceRealEstate.TradeArea]: "Торговая площадь",
    [CommerceRealEstate.Stock]: "Склад",
    [CommerceRealEstate.FreeSpace]: "Помещение свободного назначения",
    [CommerceRealEstate.Production]: "Производство",
    [CommerceRealEstate.CarService]: "Автосервис",
    [CommerceRealEstate.Building]: "Здание",
};
