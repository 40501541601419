import { DealType } from "../enums";

export const dealTypesList = [
    {
        value: DealType.Sale,
        label: "Продажа",
    },
    {
        value: DealType.RentDaily,
        label: "Посуточная аренда",
    },
    {
        value: DealType.Rent,
        label: "Долгосрочная аренда",
    },
];

export const dealTypesMap = {
    [DealType.Sale]: "Продажа",
    [DealType.RentDaily]: "Посуточная аренда",
    [DealType.Rent]: "Долгосрочная аренда",
};
