import { appEnv, languageStorageManager } from "shared";

type ResponseResultType = "json" | "text" | "blob" | "arrayBuffer" | "formData" | "raw";

type RequestOptions = RequestInit & {
    url: string;
    contentType?: ContentType | boolean;
    as?: ResponseResultType;
    token?: string;
};

class UserError {
    error: string;
    constructor(error: string) {
        this.error = error;
    }
}

export enum ContentType {
    "json" = "application/json",
}

export class BaseAPI {
    private baseUrl?: string;

    constructor(baseUrl?: string) {
        this.baseUrl = baseUrl ?? appEnv.baseApi;
    }

    protected request = async ({ url, as = "json", token, contentType, ...options }: RequestOptions) => {
        const headers = new Headers();

        headers.set("Accept-Language", languageStorageManager.language);

        if (typeof contentType !== "boolean") {
            headers.set("Content-Type", contentType ?? ContentType.json);
        }

        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }

        const reqOpts: RequestInit = {
            headers,
            ...options,
        };

        const result = await fetch(url, reqOpts).then(this.handleErrors);

        if (as === "raw") {
            return result;
        }

        return result[as]();
    };

    protected buildUrl(endpoint: string = "") {
        return `${this.baseUrl}${endpoint}`;
    }

    private handleErrors = async (response: Response) => {
        if (!response.ok) {
            const text = await response.text();

            throw new UserError(text);
        }
        return response;
    };
}
