import React from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Tooltip from "@mui/material/Tooltip";

interface Props {
    count: number;
    onClick: VoidFunction;
}

export const BadgeIconButton = React.memo((props: Props) => {
    const { t } = useTranslation();

    return (
        <Tooltip title={t`notifications`}>
            <IconButton size="small" onClick={props.onClick}>
                <Badge badgeContent={props.count} color="primary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
        </Tooltip>
    );
});
