import styled, { css } from "styled-components/macro";
import MuiButton from "@mui/material/Button";
import boxShadows from "assets/theme/base/boxShadows";

export const Button = styled(MuiButton)<{ front: "right" | "left" }>`
    && {
        position: absolute;
        top: calc(50% - 16px);
        left: ${({ front }) => (front === "right" ? `calc(100% - 40px)` : "8px")};
        border-radius: 50%;
        min-width: 38px;
        min-height: 38px;
        width: 38px;
        height: 38px;
        padding: 0;
        background-color: #ffffff;

        &:hover,
        &:focus {
            background-color: #e4e4e4 !important;
            box-shadow: ${boxShadows.md};
        }

        svg {
            fill: ${({ theme }) => theme.colors.content.primary};
        }
    }
`;

export const Container = styled.div`
    position: relative;
    width: 100%;
`;

export const ListContainerWrapper = styled.div<{ isOverflowHidden?: boolean }>`
    overflow: ${({ isOverflowHidden = false }) => (isOverflowHidden ? "hidden" : "visible")};
`;

export const ListContainer = styled.div<{ gap?: number; padding?: string }>(
    ({ gap = 0, padding = 0 }) => css`
        width: fit-content;
        display: flex;
        gap: ${gap}px;
        padding: ${padding};
        will-change: transform;
        transition: all 450ms ease-out 0s;
        user-select: none;

        &.draggable {
            cursor: grabbing;
            * {
                cursor: grabbing;
            }
        }
    `
);
