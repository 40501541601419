import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin: auto;
    color: rgba(0, 0, 0, 0.2);
    background-color: unset;
`;

export const Container = styled.div<{ src: string }>`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    filter: blur(10px);
    background-image: url(${({ src }) => src});
    background-size: cover;
    background-position: center center;
`;

export const Image = styled.img`
    position: relative;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    user-select: none;
    object-position: center;
    pointer-events: none;
`;
