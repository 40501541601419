import Cookie from "js-cookie";
import { BaseAPI } from "api/utils";
import { AdvertisementDomain } from "app-domain";

export class WishListAPI extends BaseAPI {
    public getWishList(): Promise<AdvertisementDomain.Advertisement[]> {
        const url = this.buildUrl();

        return this.request({ url, token: Cookie.get("access-token") });
    }

    public addToWishList(advertisementId: string): Promise<void> {
        const url = this.buildUrl();

        return this.request({
            url,
            method: "POST",
            body: JSON.stringify({ advertisementId }),
            token: Cookie.get("access-token"),
        });
    }
    public removeFromWishList(advertisementId: string): Promise<void> {
        const url = this.buildUrl();

        return this.request({
            url,
            method: "DELETE",
            as: "raw",
            body: JSON.stringify({ advertisementId }),
            token: Cookie.get("access-token"),
        });
    }

    protected buildUrl(endpoint: string = ""): string {
        return super.buildUrl(`/trade/wish-list${endpoint}`);
    }
}
