import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import * as Markup from "./prev-next.styles";

interface Props {
    onNextClick: VoidFunction;
    onPrevClick: VoidFunction;
}

export const PrevNext = React.memo((props: Props) => (
    <>
        <Markup.Button variant="contained" front="left" onClick={props.onPrevClick}>
            <ChevronLeftIcon />
        </Markup.Button>
        <Markup.Button variant="contained" front="right" onClick={props.onNextClick}>
            <ChevronRightIcon />
        </Markup.Button>
    </>
));
