import formatDate from "date-fns/format";
import { ru } from "date-fns/locale";
import { Locale } from "date-fns";
import intervalToDuration from "date-fns/intervalToDuration";

export enum Formats {
    Date = "dd.MM.yyyy",
    Time = "HH:mm",
    FullTime = "HH:mm:ss",
    DateTime = "dd.MM.yyyy HH:mm",
    FullDateTime = "dd.MM.yyyy HH:mm:ss",
}

export const DEFAULT_LOCALE = "ru";

type LocaleKey = "ru";

const LocaleMap = {
    ru,
} as {
    [key in LocaleKey]: Locale;
};

type FormatParams = {
    format?: string | Formats;
    locale?: LocaleKey;
};

const DEFAULT_PARAMS: FormatParams = {
    format: Formats.Date,
    locale: "ru",
};

export const format = (date?: Date | number | null, params = DEFAULT_PARAMS) => {
    if (!date) return "";
    return formatDate(date, params.format || Formats.Date, {
        locale: LocaleMap[params.locale || DEFAULT_LOCALE],
    });
};

export const calculateDuration = (start: Date, end: Date) => intervalToDuration({ start, end });
