import styled, { css } from "styled-components";

export const Image = styled.div<{ src?: string }>`
    height: 180px;
    border-radius: 0.75rem;
    background-color: #ebebeb;

    /* ::after {
        content: "no image";
        position: absolute;
        top: calc(180px / 2);
        left: calc(250px / 2);
        transform: translate(-50%, -50%);
        color: #ffffff;
    } */

    ${({ src }) => {
        if (!src) return;

        return css`
            background-image: url(${src});
            background-repeat: no-repeat;
            background-size: cover;
        `;
    }}
`;
