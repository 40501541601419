const appBar = {
    defaultProps: {
        color: "transparent",
    },

    styleOverrides: {
        root: {
            boxShadow: "none",
        },
    },
};

export default appBar;
