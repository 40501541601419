import styled from "styled-components";
import MuiButton from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";

export const CategoryContainer = styled.div`
    background-color: #ffffff;
`;

export const CategoryButton = styled(MuiButton)`
    height: 28px;
`;

export const Input = styled(InputBase)`
    z-index: 1;
    height: 30px;
    border: unset;
    padding-left: 0;
    background-color: #ffffff;
`;

export const SearchButton = styled(MuiButton)`
    z-index: 1;
    min-width: 72px;
    height: 30px;
    padding: 0 16px;
    border-color: transparent;
    border-radius: 0;
`;

export const SearchList = styled.div`
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 30px;
    z-index: 2;
    overflow: hidden;
    background-color: #ffffff;
`;

export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    border: 2px solid ${({ theme }) => theme.colors.content.primary};
`;

export const Container = styled.div`
    position: relative;
    width: 100%;
    max-width: 800px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.content.primary};

    &:has(${Input}:focus):has(${SearchList}:not(:empty)),
    &:has(${SearchList}:active) {
        ${SearchList} {
            display: block;
            border-top: 1px solid silver;
            border-right: 2px solid ${({ theme }) => theme.colors.content.primary};
            border-left: 2px solid ${({ theme }) => theme.colors.content.primary};
            border-bottom: 2px solid ${({ theme }) => theme.colors.content.primary};
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }

        ${InputContainer} {
            border-bottom-left-radius: unset;
            border-bottom-right-radius: unset;
        }
    }
`;
