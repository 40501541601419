import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Tooltip from "@mui/material/Tooltip";
import Badge, { BadgeProps } from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { Store } from "app";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
        right: -3,
        top: 4,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: "0 4px",
    },
}));

export const WishList = observer(() => {
    const store = Store.Hooks.useWishList();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClick = React.useCallback(() => {
        navigate("wish-list");
    }, [navigate]);

    return (
        <Tooltip title={t`wish-list`}>
            <IconButton onClick={handleClick} size="small">
                <StyledBadge badgeContent={store.availableIds.size} color="info">
                    <FavoriteBorderIcon />
                </StyledBadge>
            </IconButton>
        </Tooltip>
    );
});
