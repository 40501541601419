import Cookie from "js-cookie";
import { BaseAPI } from "api/utils";
import { AdvertisementDomain } from "app-domain";
import { GetListParams, RemoveMediaParams, GetListResponse } from "./advertisement-api.types";

export class AdvertisementAPI extends BaseAPI {
    public getList(params: GetListParams = {}): Promise<GetListResponse> {
        const url = this.buildUrl("/search");

        return this.request({
            url,
            method: "POST",
            body: JSON.stringify(params),
            token: Cookie.get("access-token"),
        });
    }

    public getPopular(params: { category: number }): Promise<AdvertisementDomain.Advertisement[]> {
        const url = this.buildUrl("/popular");

        return this.request({
            url,
            method: "POST",
            body: JSON.stringify(params),
            token: Cookie.get("access-token"),
        });
    }

    public changeStatus(id: string, status: AdvertisementDomain.Status): Promise<AdvertisementDomain.Advertisement> {
        const url = this.buildUrl(`/${id}/change-status`);

        return this.request({
            url,
            method: "PATCH",
            token: Cookie.get("access-token"),
            body: JSON.stringify({ status }),
        });
    }

    public removeAdvertisement(id: string) {
        const url = this.buildUrl(`/${id}`);

        return this.request({
            url,
            method: "DELETE",
            token: Cookie.get("access-token"),
        });
    }

    public getViewHistory(): Promise<AdvertisementDomain.Advertisement[]> {
        const url = this.buildUrl("/history");

        return this.request({ url, token: Cookie.get("access-token") });
    }

    public getSimilarAdvertisement(id: string): Promise<AdvertisementDomain.Advertisement[]> {
        const url = this.buildUrl("/qwerty");

        return this.request({ url, token: Cookie.get("access-token") });
    }

    public getCurrentUserAdvertisements() {
        const url = this.buildUrl("/my");

        return this.request({ url, token: Cookie.get("access-token") });
    }

    public getAdvertisementById(id: string): Promise<AdvertisementDomain.Advertisement> {
        const url = this.buildUrl(`/${id}`);

        return this.request({ url, token: Cookie.get("access-token") });
    }

    public createAdvertisement(body: FormData): Promise<AdvertisementDomain.Advertisement> {
        const url = this.buildUrl(`/`);

        return this.request({
            url,
            body,
            method: "POST",
            token: Cookie.get("access-token"),
            contentType: false,
        });
    }

    public updateAdvertisement(body: FormData): Promise<AdvertisementDomain.Advertisement> {
        const url = this.buildUrl(`/`);

        return this.request({
            url,
            body,
            method: "PATCH",
            token: Cookie.get("access-token"),
            contentType: false,
        });
    }

    public removeMedia(id: string, params: RemoveMediaParams): Promise<void> {
        const url = this.buildUrl(`/${id}/remove-media`);

        return this.request({
            url,
            body: JSON.stringify(params),
            method: "POST",
            token: Cookie.get("access-token"),
        });
    }

    protected buildUrl(endpoint: string = ""): string {
        return super.buildUrl(`/trade/advertisements${endpoint}`);
    }
}
