import React, { CSSProperties } from "react";
import { SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper";
import { AutoplayOptions } from "swiper/types/modules/autoplay";

import * as Markup from "./swiper.styles";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";

import { SwiperModule } from "swiper/types/shared";
import { PaginationOptions } from "swiper/types/modules/pagination";

interface Props {
    children?: JSX.Element[];
    navigation?: boolean;
    pagination?: PaginationOptions | boolean;
    initialSlide?: number;
    slidesPerView?: number;
    autoplay?: AutoplayOptions | boolean;
    style?: CSSProperties;
    direction?: "vertical" | "horizontal";
}

export const SwiperView = React.memo((props: Props) => {
    const { autoplay, navigation = false, pagination } = props;
    const count = props.children?.length ?? 0;

    const swiperModules = React.useMemo<SwiperModule[]>(() => {
        const result: SwiperModule[] = [];

        if (autoplay) {
            result.push(Autoplay);
        }

        if (navigation) {
            result.push(Navigation);
        }

        if (pagination) {
            result.push(Pagination);
        }

        return result;
    }, [autoplay, navigation, pagination]);

    return (
        <Markup.Container
            style={props.style}
            direction={props.direction}
            slidesPerView={props.slidesPerView}
            initialSlide={props.initialSlide}
            loop={count > 1}
            pagination={pagination}
            autoplay={autoplay}
            navigation={navigation ? count > 1 : navigation}
            modules={swiperModules}>
            {props.children?.map((item, index) => (
                <SwiperSlide key={index}>{item}</SwiperSlide>
            ))}
        </Markup.Container>
    );
});
