import { palette } from "assets/theme/base/palette";

const select = {
    styleOverrides: {
        select: {
            "display": "grid",
            "alignItems": "center",

            "& .Mui-selected": {
                backgroundColor: palette.transparent.main,
            },
        },

        selectMenu: {
            background: "none",
            height: "none",
            minHeight: "none",
            overflow: "unset",
        },

        icon: {
            display: "none",
        },
    },
};

export default select;
