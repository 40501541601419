import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { store } from "./store";
import { EmptyDrawer } from "./empty";
import { BadgeIconButton } from "./bange-icon-button";
import * as Markup from "./notifications.styles";

export const Notifications = observer(() => {
    const { t } = useTranslation();

    return (
        <>
            <BadgeIconButton count={store.list.length} onClick={store.openNotifications} />
            <Drawer anchor="right" open={store.isNotificationsOpen} onClose={store.closeNotifications}>
                <Markup.Container>
                    <AppBar style={{ height: 58 }} position="static">
                        <Toolbar>
                            <Typography style={{ flexGrow: 1, color: "#ffffff" }} variant="h6">
                                {t`notifications`}
                            </Typography>
                            <IconButton
                                aria-label="close"
                                edge="start"
                                color="inherit"
                                onClick={store.closeNotifications}>
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {!store.list.length && <EmptyDrawer />}
                </Markup.Container>
            </Drawer>
        </>
    );
});
