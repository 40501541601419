import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { LanguageStorageManager } from "./language-storage-manager";

export const languageStorageManager = new LanguageStorageManager("language");

i18n.use(initReactI18next);

const lng = languageStorageManager.language;

fetch("/meta-data/translations.json")
    .then((res) => res.json())
    .then((resources) => {
        i18n.init({
            resources,
            lng,
            interpolation: {
                escapeValue: false,
            },
        });
    });

export default i18n;
