// import { palette } from "assets/theme/base/palette";
// import borders from "assets/theme/base/borders";

// Material Dashboard 2 React helper functions
// import { pxToRem } from "assets/theme/functions";

const sidenav = {
    styleOverrides: {
        // root: {
        //   width: pxToRem(250),
        //   whiteSpace: "nowrap",
        //   border: "none",
        //   marginRight: pxToRem(16),
        // },
        //
        // paper: {
        //   width: pxToRem(250),
        //   backgroundColor: palette.white.main,
        //   height: `calc(100vh - ${pxToRem(83)})`,
        //   margin: `${pxToRem(67)} ${pxToRem(16)} 0`,
        //   borderRadius: borders.borderRadius.xl,
        //   border: "none",
        // },
        //
        // paperAnchorDockedLeft: {
        //   borderRight: "none",
        // },
    },
};

export default sidenav;
