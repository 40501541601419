export enum AdvertisementCategoryCode {
    Transport = 1,
    Realty = 2,
    Appliances = 3,
    Electronics = 4,
    Food = 5,
    SportHobby = 6,
    HomeGarden = 7,
    Animals = 8,
    EquipmentMaterials = 9,
    FashionStyle = 10,
}
