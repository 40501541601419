import React from "react";
import i18n, { languageStorageManager } from "shared/i18n";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import LanguageIcon from "@mui/icons-material/Language";
import { menuItems } from "./language-select.constants";

const expandLess = <ExpandLessIcon style={{ marginLeft: "auto" }} />;
const expandMore = <ExpandMoreIcon style={{ marginLeft: "auto" }} />;

export const LanguageSelect = React.memo(() => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [value, setValue] = React.useState(i18n.language);

    const handleChange = React.useCallback(
        (value: string) => () => {
            languageStorageManager.setLanguage(value);
            setValue(value);
            setIsOpen(false);
        },
        []
    );

    const handleOpenClose = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <>
            <MenuItem onClick={handleOpenClose}>
                <LanguageIcon />
                {menuItems[value].label}
                {isOpen ? expandLess : expandMore}
            </MenuItem>
            <Collapse in={isOpen}>
                <MenuItem selected={value === "hy"} onClick={handleChange("hy")}>
                    {menuItems.hy.icon} {menuItems.hy.label}
                </MenuItem>
                <MenuItem selected={value === "ru"} onClick={handleChange("ru")}>
                    {menuItems.ru.icon} {menuItems.ru.label}
                </MenuItem>
                <MenuItem selected={value === "en"} onClick={handleChange("en")}>
                    {menuItems.en.icon} {menuItems.en.label}
                </MenuItem>
            </Collapse>
        </>
    );
});
