import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
    height: 100px;
    justify-content: space-between;
    border-radius: 12px;
    background-color: #ffffff;
    flex-grow: 1;
`;
