import React from "react";
import { NavLink } from "react-router-dom";
import CardMedia from "@mui/material/CardMedia";
import Skeleton from "@mui/material/Skeleton";
import MDBox from "components/MDBox";
import { cardMediaStyles } from "./media-card.constants";

interface Props {
    route?: string;
    title?: string;
    isLoading?: boolean;
    image?: string;
}

export const MediaCard = React.memo((props: Props) =>
    props.isLoading ? (
        <Skeleton variant="rounded" width="100%" height="180px" />
    ) : (
        <MDBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
            <NavLink to={props.route ?? ""}>
                <CardMedia src={props.image} component="img" title={props.title} sx={cardMediaStyles} />
            </NavLink>
        </MDBox>
    )
);
