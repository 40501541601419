import React from "react";
import MDBox from "components/MDBox";
import Skeleton from "@mui/material/Skeleton";
import MDTypography from "components/MDTypography";
import { AdvertisementDomain, DictionaryDomain } from "app-domain";
import { descriptionStyles } from "../../advertment-card.constants";

export interface InfoCardProps {
    currencies: Map<string, DictionaryDomain.Currency>;
    title?: string;
    isLoading?: boolean;
    status?: AdvertisementDomain.Status;
    price?: AdvertisementDomain.Price;
    description?: NullableString;
}

export const InfoCard = React.memo((props: InfoCardProps) => {
    const price = props.price?.value.start;
    const currency = props.price?.currencyType ? props.currencies?.get(props.price?.currencyType)?.symbol : "";

    return (
        <MDBox mt={1} mx={0.5}>
            {props.isLoading ? (
                <Skeleton variant="rectangular" width="30%" height={14} />
            ) : (
                <MDTypography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
                    {price ? `${price.toLocaleString("ru")} ${currency}` : "Цена договорная"}
                </MDTypography>
            )}

            <MDBox my={1}>
                {props.isLoading ? (
                    <Skeleton variant="rectangular" width="70%" height={24} />
                ) : (
                    <MDTypography
                        sx={{
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                        }}
                        component="a"
                        target="_blank"
                        rel="noreferrer"
                        variant="h5"
                        textTransform="capitalize"
                    >
                        {props.title}
                    </MDTypography>
                )}
            </MDBox>
            {!!props.description && (
                <MDBox lineHeight={0}>
                    <MDTypography style={descriptionStyles} variant="button" fontWeight="light" color="text">
                        {props.description}
                    </MDTypography>
                </MDBox>
            )}
        </MDBox>
    );
});
