import React from "react";
import * as Markup from "./overview-card.styles";

interface Props {
    title: string;
    icon: string;
    bg: string;
    path: string;
}

export const OverviewCard = React.memo((props: Props) => {
    return (
        <Markup.Link bg={props.bg} icon={props.icon} to={props.path}>
            {props.title}
        </Markup.Link>
    );
});
