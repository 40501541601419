// Material Dashboard 2 React Helper Functions
import { pxToRem } from "assets/theme/functions";

const cardContent = {
    styleOverrides: {
        root: {
            marginTop: 0,
            marginBottom: 0,
            padding: `${pxToRem(8)} ${pxToRem(24)} ${pxToRem(24)}`,
        },
    },
};

export default cardContent;
