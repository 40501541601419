import { makeAutoObservable, runInAction } from "mobx";
import { AdvertisementDomain } from "app-domain";
import { UserAdvertisementsAdapter } from "./user-advertisements.types";
import { countByStatus, groupAdvertisementsByStatus } from "./user-advertisements.utils";

export class UserAdvertisements {
    public isLoading = false;
    public isInitialized = false;
    public list: AdvertisementDomain.Advertisement[] = [];
    public groupedListByStatus = countByStatus();

    constructor(private service: UserAdvertisementsAdapter) {
        makeAutoObservable(this);
    }

    public async loadUserAdvertisements() {
        if (this.isInitialized || this.isLoading) return;

        this.isLoading = true;

        try {
            const result = await this.service.getCurrentUserAdvertisements();

            runInAction(() => {
                this.isLoading = false;
                this.isInitialized = true;
                this.list = result;
                this.groupedListByStatus = groupAdvertisementsByStatus(result);
            });
        } catch {
            this.isLoading = false;
        }
    }

    public async changeStatus(id: string, status: AdvertisementDomain.Status) {
        const indexOfAdv = this.list.findIndex((item) => item.id === id);

        if (indexOfAdv < 0) return;

        try {
            const result = await this.service.changeStatus(id, status);

            runInAction(() => {
                this.list[indexOfAdv] = result;
            });
        } catch (error) {
            console.log(error);
        }
    }

    public async removeAdvertisement(id: string) {
        const indexOfAdv = this.list.findIndex((item) => item.id === id);

        if (indexOfAdv < 0) return;

        try {
            await this.service.removeAdvertisement(id);

            runInAction(() => {
                this.list = this.list.filter((item) => item.id !== id);
                this.groupedListByStatus = groupAdvertisementsByStatus(this.list);
            });
        } catch (error) {
            console.log(error);
        }
    }

    public reload() {
        this.isInitialized = false;
        this.loadUserAdvertisements();
    }
}
