import { TypographyProps } from "@mui/material/Typography";
import MDTypographyRoot, { OwnerState } from "./MDTypographyRoot";

type Props = Omit<TypographyProps, "fontWeight"> & Partial<OwnerState> & { style?: React.CSSProperties } & any;

const MDTypography = ({
    color = "dark",
    fontWeight = false,
    textTransform = "none",
    verticalAlign = "unset",
    textGradient = false,
    opacity = 1,
    ...rest
}: Props) => {
    return (
        <MDTypographyRoot
            {...rest}
            ownerState={{
                color,
                textTransform,
                verticalAlign,
                fontWeight,
                opacity,
                textGradient,
            }}
        />
    );
};

export default MDTypography;
