import React from "react";
import Avatar from "@mui/material/Avatar/Avatar";
import IconButton from "@mui/material/IconButton";
import { Store } from "app";

import { UserMenu } from "./user-menu";
import * as Markup from "./tools-button.styles";

export const ToolsButton = React.memo(() => {
    const userStore = Store.Hooks.useUserStore();

    const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);

    const handleMouseEnter = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchor(event.currentTarget);
    }, []);

    const handleClose = React.useCallback(() => {
        setAnchor(null);
    }, []);

    return (
        <Markup.Container>
            <IconButton onClick={handleMouseEnter} size="small" aria-haspopup="true">
                <Avatar sx={{ width: 24, height: 24, fontSize: 16, lineHeight: 24 }}>
                    {userStore.profile?.firstName[0]}
                </Avatar>
            </IconButton>

            {anchor && <UserMenu anchor={anchor} handleClose={handleClose} />}
        </Markup.Container>
    );
});
