import React from "react";
import * as Markup from "./swiper-hover.styles";

interface Props {
    children?: JSX.Element[];
}

export const SwiperHover = React.memo((props: Props) => {
    const [active, setActive] = React.useState(0);
    const count = Math.min(5, props.children?.length ?? 0);

    const bullets = React.useMemo(() => {
        const list: JSX.Element[] = [];

        for (let i = 0; i < count; i++) {
            list.push(<Markup.Bullet key={i} active={i === active} />);
        }

        return list;
    }, [count, active]);

    const onMouseOver = (event: React.MouseEvent<HTMLDivElement>) => {
        const width = event.currentTarget.clientWidth;
        const position = event.nativeEvent.offsetX;
        const widthPerCount = width / count;

        setActive(Math.min(Math.max(Math.floor(position / widthPerCount), 0), count - 1));
    };

    const handleMouseOut = () => {
        setActive(0);
    };

    if (count < 2) return <>{props.children}</>;

    return (
        <Markup.SwiperHover onMouseOut={handleMouseOut} onMouseMove={onMouseOver}>
            {props.children?.[active]}
            <Markup.BulletContainer>{bullets}</Markup.BulletContainer>
        </Markup.SwiperHover>
    );
});
