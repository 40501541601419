import React from "react";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import * as Markup from "./header.styles";

export const Header = React.memo((props: React.PropsWithChildren) => (
    <Markup.Container>
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
            {props.children}
        </Typography>
    </Markup.Container>
));
