import { makeAutoObservable } from "mobx";

class Notifications {
    public isNotificationsOpen = false;

    public list: unknown[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    public openNotifications = () => {
        this.isNotificationsOpen = true;
    };

    public closeNotifications = () => {
        this.isNotificationsOpen = false;
    };
}

export const store = new Notifications();
