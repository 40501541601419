import { AdvertisementDomain } from "app-domain";
import { CountByStatus } from "./user-advertisements.types";

export const countByStatus = (): CountByStatus => ({
    [AdvertisementDomain.Status.Draft]: [],
    [AdvertisementDomain.Status.Moderation]: [],
    [AdvertisementDomain.Status.Active]: [],
    [AdvertisementDomain.Status.Archived]: [],
});

export const groupAdvertisementsByStatus = (list: AdvertisementDomain.Advertisement[]): CountByStatus =>
    list.reduce((result, adv) => {
        result[adv.status].push(adv);
        return result;
    }, countByStatus());
