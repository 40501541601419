import { palette } from "assets/theme/base/palette";
import typography from "assets/theme/base/typography";
import { pxToRem } from "assets/theme/functions";

const { white, info, secondary } = palette;
const { size } = typography;

const contained = {
    base: {
        "backgroundColor": white.main,
        "minHeight": pxToRem(32),
        "padding": `${pxToRem(4)} ${pxToRem(24)}`,

        "&:hover": {
            backgroundColor: white.main,
        },

        "&:active, &:active:focus, &:active:hover": {
            opacity: 0.85,
        },

        "& .material-icon, .material-icons-round, svg": {
            fontSize: `${pxToRem(28)} !important`,
        },
    },

    small: {
        "minHeight": pxToRem(24),
        "padding": `${pxToRem(0)} ${pxToRem(16)}`,
        "fontSize": size.xs,
        "lineHeight": pxToRem(34),

        "& .material-icon, .material-icons-round, svg": {
            fontSize: `${pxToRem(16)} !important`,
        },
    },

    large: {
        "minHeight": pxToRem(47),
        "padding": `${pxToRem(12)} ${pxToRem(28)}`,
        "fontSize": size.sm,

        "& .material-icon, .material-icons-round, svg": {
            fontSize: `${pxToRem(22)} !important`,
        },
    },

    primary: {
        "backgroundColor": info.main,

        "&:hover": {
            backgroundColor: info.main,
        },

        "&:focus:not(:hover)": {
            backgroundColor: info.focus,
        },
    },

    secondary: {
        "backgroundColor": secondary.main,

        "&:hover": {
            backgroundColor: secondary.main,
        },

        "&:focus:not(:hover)": {
            backgroundColor: secondary.focus,
        },
    },
};

export default contained;
