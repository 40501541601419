import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

const List = React.lazy(() => import("pages/advertisements-list"));
const AdvertisementDetails = React.lazy(() => import("pages/advertisement-details"));
const General = React.lazy(() => import("pages/general"));
const Profile = React.lazy(() => import("pages/profile"));
const NewAdv = React.lazy(() => import("pages/new-adv"));
const EditAdv = React.lazy(() => import("pages/edit-adv"));
const WishList = React.lazy(() => import("pages/wish-list"));
const Verify = React.lazy(() => import("pages/verify"));

export const Routing = React.memo(() => (
    <Routes>
        <Route index element={<General />} />
        <Route path="adv/:id" element={<AdvertisementDetails />} />
        <Route path="profile/*" element={<Profile />} />
        <Route path="new-adv" element={<NewAdv />} />
        <Route path="verify" element={<Verify />} />
        <Route path="edit-adv/:id" element={<EditAdv />} />
        <Route path="wish-list" element={<WishList />} />
        <Route path="category/" element={<List />} />
        <Route path="category/:category" element={<List />} />
        <Route path="*" element={<Navigate to="/" />} />
    </Routes>
));
