export const fileReader = (files: File[] | FileList) => {
    const result: Promise<string>[] = [];

    for (let index = 0; index < files.length; index++) {
        let file = files[index];

        const reader = new FileReader();

        reader.readAsDataURL(file);

        const promise: Promise<string> = new Promise((resolve) => {
            reader.onload = () => {
                if (typeof reader.result === "string") {
                    resolve(reader.result);
                }
            };

            reader.onerror = () => {
                console.log(reader.error);
            };
        });

        result.push(promise);
    }

    return Promise.all(result);
};
