type ValidatedResponseResult<Data extends object> = {
    data: Data;
    error?: string;
};

type Value<Data extends object> =
    | {
          isOk: true;
          data: Data;
      }
    | {
          isOk: false;
          error: string;
      };

export class ValidatedResult<Data extends object> {
    public value!: Value<Data>;

    constructor(responseResult: ValidatedResponseResult<Data>) {
        if (responseResult.error) {
            this.value = {
                isOk: false,
                error: responseResult.error,
            };
        } else {
            this.value = {
                isOk: true,
                data: responseResult.data,
            };
        }
    }
}
