const roomsCount = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8+", value: "8+" },
];

export const buildingType = [
    {
        label: "Каменное",
        value: "1",
    },
    {
        label: "Панельное",
        value: "2",
    },
    {
        label: "Монолит",
        value: "3",
    },
    {
        label: "Кирпичное",
        value: "4",
    },
    {
        label: "Кассетное",
        value: "5",
    },
    {
        label: "Деревянное",
        value: "6",
    },
];

const repair = [
    {
        value: "1",
        label: "Без ремонта",
    },
    {
        value: "2",
        label: "Старый ремонт",
    },
    {
        value: "3",
        label: "Частичный ремонт",
    },
    {
        value: "4",
        label: "Комсметический ремонт",
    },
    {
        value: "5",
        label: "Евроремонт",
    },
    {
        value: "6",
        label: "Дизайнерский ремонт",
    },
    {
        value: "7",
        label: "Копитальный ремонт",
    },
];

interface Option {
    value: string;
    label: string;
}

export const fieldsOptions: Record<string, Option[]> = {
    roomsCount,
    buildingType,
    repair,
};
