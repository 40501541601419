import styled from "styled-components";
import Card from "@mui/material/Card";

export const Container = styled(Card)`
    display: flex;
    flex-direction: column;
    background-color: transparent;
    box-shadow: none;
    overflow: visible;
    max-width: 270px;
    min-width: 220px;
    width: 100%;
`;
